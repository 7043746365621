import ScheduledTransactionsMigration from "pages/ScheduledTransactionsMigration";
import TokenActivationFailed from "pages/Token/TokenActivationFailed";
import TokenActivationPending from "pages/Token/TokenActivationPending";
import TokenActivationStep1 from "pages/Token/TokenActivationStep1";
import TokenActivationStep2 from "pages/Token/TokenActivationStep2";
import TokenActivationSuccess from "pages/Token/TokenActivationSuccess";
import BackButtonListener from "pages/_components/BackButtonListener";
import { resizableRoute } from "pages/_components/Resizable";
import TermsAndConditions from "pages/_components/TermsAndConditions";
import DashboardLayout from "pages/_layouts/DashboardLayout";
import DefaultLayout from "pages/_layouts/DefaultLayout";
import DefaultStepperLayout from "pages/_layouts/DefaultStepperLayout/DefaultStepperLayout";
import Accounts from "pages/accounts/Accounts";
import AccountDetail from "pages/accounts/Details";
import MovementDetail from "pages/accounts/_components/MovementDetail";
import SetAlias from "pages/accounts/_components/SetAlias";
import Administration from "pages/administration/Administration";
import UserInvite from "pages/administration/UserInvite";
import UserInviteStep2 from "pages/administration/UserInviteStep2";
import UserInviteStep3 from "pages/administration/UserInviteStep3";
import AdministrationMediumSignatureTicket from "pages/administration/medium/tickets/SignatureTicket";
import Checks from "pages/checks/Checks";
import DetailCheck from "pages/checks/Details";
import CommunicationMain from "pages/communications/Main";
import CreditCards from "pages/creditCards";
import DetailFinancingPlans from "pages/creditCards/DetailFinancingPlans";
import CreditCardDetails from "pages/creditCards/Details";
import CreditrCardMovementDetails from "pages/creditCards/_components/MovementDetails";
import CreditLines from "pages/creditLines/CreditLines";
import DebitCards from "pages/debitCards/DebitCards";
import Deposits from "pages/deposits/Deposits";
import DepositDetail from "pages/deposits/Details";
import DepositSetAlias from "pages/deposits/_components/SetAlias";
import Desktop from "pages/desktop/Desktop";
import GeneralConditionEnvironment from "pages/environment/GeneralConditionEnvironment";
import Finances from "pages/finances/Finances";
import ConfirmationModal from "pages/forms/_components/ConfirmationModal/ConfirmationModal";
import CashAdvanceForm from "pages/forms/customForms/CashAdvanceForm";
import CreateTokenPinForm from "pages/forms/customForms/CreateTokenPinForm";
import FinancingPlanForm from "pages/forms/customForms/FinancingPlanForm";
import LoanPaymentForm from "pages/forms/customForms/LoanPaymentForm";
import PayCreditCardLocal from "pages/forms/customForms/PayCreditCardLocal";
import RechargeCreditCardLocal from "pages/forms/customForms/RechargeCreditCardLocal";
import ReferenceLetter from "pages/forms/customForms/ReferenceLetter";
import TransactionCustom from "pages/forms/customForms/TransactionCustom";
import TransferForeignForm from "pages/forms/customForms/TransferForeignForm";
import TransferInternalForm from "pages/forms/customForms/TransferInternalForm";
import TransferLocalForm from "pages/forms/customForms/TransferLocalForm";
import CreateFixedTermDepositForm from "pages/forms/customForms/fixedTermDeposit/CreateFixedTermDepositForm";
import CreateBeneficiary from "pages/frequentDestination/CreateBeneficiary";
import DetailModiyDeleteFrequentDestinatio from "pages/frequentDestination/DetailsModifyDeleteFrequentDestination";
import ListBeneficiary from "pages/frequentDestination/ListBeneficiary";
import ViewBeneficiary from "pages/frequentDestination/ViewBeneficiary";
import LoanDetails from "pages/loans/Details";
import Loans from "pages/loans/Loans";
import LoanSetAlias from "pages/loans/_components/SetAlias";
import AddNewServiceCategoryList from "pages/servicePayments/AddNewServiceCategoryList";
import AddNewServiceCompany from "pages/servicePayments/AddNewServiceCompany";
import AddNewServiceForm from "pages/servicePayments/AddNewServiceForm";
import EditFastRecharge from "pages/servicePayments/EditFastRecharge";
import EditServicePayment from "pages/servicePayments/EditServicePayment";
import ServicePaymentForm from "pages/servicePayments/ServicePaymentForm";
import ServicePaymentList from "pages/servicePayments/ServicePaymentList";
import AuthenticatorHandler from "pages/settings/AuthenticatorHandler";
import ChangeAvatar from "pages/settings/ChangeAvatar";
import ChangeEmail from "pages/settings/ChangeEmail";
import ChangeEmailConfirmation from "pages/settings/ChangeEmailConfirmation";
import ChangeEnvironment from "pages/settings/ChangeEnvironment";
import ChangeLanguage from "pages/settings/ChangeLanguage";
import ChangePassword from "pages/settings/ChangePassword";
import ChangePhone from "pages/settings/ChangePhone";
import ChangePhoneConfirmation from "pages/settings/ChangePhoneConfirmation";
import FingerprintConfiguration from "pages/settings/FingerprintConfiguration";
import FingerprintDeleteConfirmation from "pages/settings/FingerprintDeleteConfirmation";
import LastLogin from "pages/settings/LastLogin";
import MyDevices from "pages/settings/MyDevices";
import NotificationsConfiguration from "pages/settings/NotificationsConfiguration";
import NotificationsConfigurationTransports from "pages/settings/NotificationsConfigurationTransports";
import PushNotifications from "pages/settings/PushNotifications";
import PushNotificationsDeleteConfirmation from "pages/settings/PushNotificationsDeleteConfirmation";
import SetDefaultEnvironment from "pages/settings/SetDefaultEnvironment";
import Settings from "pages/settings/Settings";
import PersonalDataUpdateStep1 from "pages/settings/personalDataUpdate/PersonalDataUpdateStep1";
import PersonalDataUpdateStep2 from "pages/settings/personalDataUpdate/PersonalDataUpdateStep2";
import PersonalDataUpdateStep3 from "pages/settings/personalDataUpdate/PersonalDataUpdateStep3";
import PersonalDataUpdateStep4 from "pages/settings/personalDataUpdate/PersonalDataUpdateStep4";
import PersonalDataUpdateStep5 from "pages/settings/personalDataUpdate/PersonalDataUpdateStep5";
import PersonalDataUpdateStep6 from "pages/settings/personalDataUpdate/PersonalDataUpdateStep6";
import PersonalDataUpdateStep7 from "pages/settings/personalDataUpdate/PersonalDataUpdateStep7";
import personalDataUpdateList from "pages/settings/personalDataUpdate/personalDataUpdateList";
import SoftTokenValidateEntrustCode from "pages/softToken/SoftTokenValidateEntrustCode";
import SoftTokenValidateOtp from "pages/softToken/SoftTokenValidateOtp";
import SessionAboutToExpire from "pages/status/SessionAboutToExpire";
import SessionCLose from "pages/status/SessionCLose";
import PendingTransactionList from "pages/transactions/PendingTransactionList";
import TransactionsList from "pages/transactions/TransactionsList";
import TransactionsListHistoric from "pages/transactions/TransactionsListHistoric";
import EnrollmentWally from "pages/wally/EnrollmentWally/EnrollmentWally";
import EnrollmentWallyFinish from "pages/wally/EnrollmentWally/EnrollmentWallyFinish";
import EnrollmentWallySelectAccount from "pages/wally/EnrollmentWally/EnrollmentWallySelectAccount";
import LastMovementsWally from "pages/wally/LastMovementsWally";
import OneTouchAccessWally from "pages/wally/OneTouchAccessWally";
import PendingWally from "pages/wally/PendingWally";
import RequestWally from "pages/wally/RequestWally";
import SendWally from "pages/wally/SendWally";
import SendWallyAddContact from "pages/wally/SendWallyAddContact";
import SendWallyTransaction from "pages/wally/SendWallyTransaction";
import SettingsWally from "pages/wally/SettingsWally";
import ChangeAccountStep1 from "pages/wally/settingsOpcions/ChangeAccountStep1";
import ChangePhoneNumberStep1 from "pages/wally/settingsOpcions/ChangePhoneNumberStep1";
import ChangePhoneNumberStep2 from "pages/wally/settingsOpcions/ChangePhoneNumberStep2";
import DeactivationWally from "pages/wally/settingsOpcions/DeactivationWally";
import { bool, func, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";
// import InstallmentPurchaseForm from "pages/forms/customForms/InstallmentPurchaseForm";
import { INSERT_SECURITY_QUESTION, UPDATE_SECURITY_QUESTION } from "constants.js";
import InternationalTransferTracking from "pages/InternationalTransferTracking/InternationalTransferTracking";
import RequestsAndComplaints from "pages/RequestsAndComplaints/RequestsAndComplaints";
import TokenActivationAttemps from "pages/Token/TokenActivationAttemps";
import TokenActivationFailedDocument from "pages/Token/TokenActivationFailedDocument";
import UiComponents from "pages/UiComponents";
import AccountRequestModal from "pages/_components/modal/AccountRequestModal";
import DepositsMobileFilter from "pages/deposits/DepositsMobileFilter";
import DeviceRequired from "pages/error/DeviceRequired";
import ErrorPage from "pages/error/ErrorPage";
import ProductRequestModal from "pages/forms/_components/ProductRequestModal/ProductRequestModal";
import ReportReplaceCard from "pages/forms/customForms/ReportReplaceCard";
import RegisterQuestionsFlow from "pages/securityQuestions/RegisterQuestionsFlow";
import SecurityQuestions from "pages/securityQuestions/SecurityQuestions";
import ChangeAvatarConfirmation from "pages/settings/ChangeAvatarConfirmation";
import ChangeDefaultEnvironment from "pages/settings/ChangeDefaultEnvironment";
import ChangeDefaultEnvironmentConfirmation from "pages/settings/ChangeDefaultEnvironmentConfirmation";
import ChangePasswordConfirm from "pages/settings/ChangePasswordConfirm";
import ChangePasswordSuccess from "pages/settings/ChangePasswordSuccess";
import SoftTokenActivationFlow from "pages/softToken/SoftTokenActivationFlow";
import SoftTokenDeactivationFlow from "pages/softToken/deactivateSoftToken/SoftTokenDeactivationFlow";
import UnlockDeviceFlow from "pages/unlockDevice/UnlockDeviceFlow";

// import UiComponents from "pages/UiComponents";

// BORRAR?
// import RequestLoanForm from "pages/forms/customForms/RequestLoanForm";
// MVP2
// import AccountOpeningRequestForm from "pages/forms/customForms/AccountOpeningRequestForm";
// Empresas
// import RolePaymentForm from "pages/forms/customForms/RolePaymentForm";
// EMPRESAS PAGO DE SUELDO
// import TransactionPaymentLines from "pages/forms/TransactionPaymentLines";
// EMPRESAS?
// import Templates from "pages/forms/_components/Templates";
// import BankSearch from "pages/forms/_components/_fields/_bankselector/bankSearch/BankSearch";
// import BankSearchCustom from "pages/forms/_components/_fields/_bankselector/bankSearch/BankSearchCustom";

class PrivateRoutes extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool,
        location: shape({
            pathname: string,
        }).isRequired,
        isMobileNative: bool.isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        dispatch(statusActions.saveLastHref(location));
    }

    render() {
        const { hasActiveSession, isMobileNative, permissionInsertQuestions, permissionUpdateQuestions } = this.props;

        if (!hasActiveSession) {
            return <Redirect to="/" />;
        }

        return (
            <Fragment>
                <Switch>
                    <DashboardLayout scrollable dashboard exact path="/desktop" component={Desktop} />
                    <DashboardLayout scrollable dashboard exact path="/desktop/:id" component={Desktop} />
                    {/* CARDS */}
                    <DefaultLayout
                        exact
                        path="/creditCards"
                        component={CreditCards}
                        aditionalClassName="background-gradient"
                    />
                    <DefaultLayout
                        exact
                        path="/creditCards/financingplans/:id"
                        component={DetailFinancingPlans}
                        transition="transition-drill-in"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/creditCards/:id"
                        component={CreditCardDetails}
                        transition="transition-drill-in"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/creditCardAdditional/:id"
                        component={CreditCardDetails}
                        transition="transition-drill-in"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/creditCards/:id/:idStatement"
                        component={CreditrCardMovementDetails}
                        transition="transition-flow-open"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/cashAdvance"
                        component={CashAdvanceForm}
                        transition="transition-flow-open"
                        permissions={["cashAdvance"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/reportReplaceCard/:id"
                        component={ReportReplaceCard}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/renewCard/:id"
                        component={ReportReplaceCard}
                        transition="transition-flow-open"
                        noBar
                    />
                    {/* DEBIT CARDS */}
                    <DefaultLayout exact path="/debitCards" component={DebitCards} noBar />

                    {/* ACCOUNTS */}
                    <DefaultLayout exact path="/accounts" component={Accounts} />

                    {/* NO BORRAR MVP2 */}
                    {/* <DefaultLayout
                        exact
                        path="/accounts/openingRequest"
                        component={AccountOpeningRequestForm}
                        permissions={["accountOpen"]}
                    /> */}
                    {/* END NO BORRAR MVP2 */}

                    <DefaultLayout
                        exact
                        path="/accounts/:id"
                        component={AccountDetail}
                        transition="transition-drill-in"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/accounts/:id/movement"
                        component={MovementDetail}
                        transition="transition-flow-open"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/accounts/:id/setAlias"
                        component={SetAlias}
                        transition="transition-flow-open"
                        noBar
                    />
                    {/* CREDITLINES */}
                    <DefaultLayout exact path="/creditLines" component={CreditLines} />

                    {/* CHECKS */}
                    {/* <DefaultLayout exact path="/checkbooks/:id" component={Checkbooks} />
                    <DefaultLayout exact path="/checks/:idProduct/:idCheckbook" component={Checks} />
                    <DefaultLayout exact path="/formCustom/stopChecks" component={StopChecksForm} />
                    <DefaultLayout exact path="/formCustom/stopCheckbooks" component={StopChecksForm} />
                    <DefaultLayout exact path="/searchchecks/:idProduct" component={Checks} /> */}
                    <DefaultLayout exact path="/checks" component={Checks} />
                    <DefaultLayout
                        exact
                        path="/checks/:id"
                        component={DetailCheck}
                        transition="transition-drill-in"
                        defaultValidationPosition={false}
                    />
                    {/* DEPOSITS */}
                    <DefaultLayout
                        exact
                        path="/deposits"
                        component={Deposits}
                        aditionalClassName="background-gradient"
                    />
                    <DefaultLayout exact path="/deposits/filter" component={DepositsMobileFilter} />
                    <DefaultLayout
                        exact
                        path="/deposits/:id"
                        component={DepositDetail}
                        transition="transition-drill-in"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/deposits/:id/setAlias"
                        component={DepositSetAlias}
                        transition="transition-flow-open"
                        defaultValidationPosition={false}
                    />

                    {/* SERVICE PAYMENTS */}
                    <DefaultLayout
                        exact
                        path="/servicePayments"
                        component={ServicePaymentList}
                        permissions={["servicePayment"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/servicePayments/editService/:id"
                        component={EditServicePayment}
                        permissions={["servicePayment"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/servicePayments/editFastRecharge/:id"
                        component={EditFastRecharge}
                        permissions={["servicePayment"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/addNewService/category"
                        component={AddNewServiceCategoryList}
                        permissions={["servicePayment"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/addNewService/company/:idServicePayment"
                        component={AddNewServiceCompany}
                        permissions={["servicePayment"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/addNewService/addNewServiceForm/:idBiller"
                        component={AddNewServiceForm}
                        permissions={["servicePayment"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/servicePayment"
                        component={ServicePaymentForm}
                        permissions={["servicePayment"]}
                        noBar
                    />
                    <DefaultLayout exact path="/securityQuestions" component={SecurityQuestions} noBar />

                    <DefaultLayout
                        exact
                        path="/registerQuestions"
                        component={RegisterQuestionsFlow}
                        componentProps={{ action: "register" }}
                        noBar
                    />

                    <DefaultLayout
                        exact
                        path="/updateQuestions"
                        component={RegisterQuestionsFlow}
                        componentProps={{ action: "update" }}
                        noBar
                    />

                    <DefaultLayout exact path="/settings" component={Settings} noBar />
                    <DefaultStepperLayout
                        exact
                        title="settings.options.updateUserData"
                        path="/settings/personalDataUpdateStep1"
                        stepsList={personalDataUpdateList}
                        step={1}
                        completed={0}
                        component={PersonalDataUpdateStep1}
                        noBar
                        customBackURL="/settings"
                    />

                    <DefaultStepperLayout
                        exact
                        title="settings.options.updateUserData"
                        path="/settings/personalDataUpdateStep2"
                        stepsList={personalDataUpdateList}
                        step={2}
                        completed={1}
                        component={PersonalDataUpdateStep2}
                        noBar
                        customBackURL="/settings"
                    />

                    <DefaultStepperLayout
                        exact
                        title="settings.options.updateUserData"
                        path="/settings/personalDataUpdateStep3"
                        stepsList={personalDataUpdateList}
                        step={3}
                        completed={2}
                        component={PersonalDataUpdateStep3}
                        noBar
                        customBackURL="/settings"
                    />
                    <DefaultStepperLayout
                        exact
                        title="settings.options.updateUserData"
                        path="/settings/personalDataUpdateStep4"
                        stepsList={personalDataUpdateList}
                        step={4}
                        completed={3}
                        component={PersonalDataUpdateStep4}
                        noBar
                        customBackURL="/settings"
                    />
                    <DefaultStepperLayout
                        exact
                        title="settings.options.updateUserData"
                        path="/settings/personalDataUpdateStep5"
                        stepsList={personalDataUpdateList}
                        step={5}
                        completed={4}
                        component={PersonalDataUpdateStep5}
                        noBar
                        customBackURL="/settings"
                    />
                    <DefaultStepperLayout
                        exact
                        title="settings.options.updateUserData"
                        path="/settings/personalDataUpdateStep6"
                        stepsList={personalDataUpdateList}
                        step={6}
                        completed={5}
                        component={PersonalDataUpdateStep6}
                        noBar
                        customBackURL="/settings"
                    />
                    <DefaultStepperLayout
                        exact
                        title="settings.options.updateUserData"
                        path="/settings/personalDataUpdateStep7"
                        stepsList={personalDataUpdateList}
                        step={6}
                        completed={6}
                        component={PersonalDataUpdateStep7}
                        noBar
                        customBackURL="/settings"
                    />

                    <DefaultLayout
                        exact
                        path="/settings/changePassword"
                        component={ChangePassword}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePassword/confirm"
                        component={ChangePasswordConfirm}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePassword/success"
                        component={ChangePasswordSuccess}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeAvatar"
                        component={ChangeAvatar}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeAvatar/confirmation"
                        component={ChangeAvatarConfirmation}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeDefaultEnvironment"
                        component={ChangeDefaultEnvironment}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeDefaultEnvironment/confirmation"
                        component={ChangeDefaultEnvironmentConfirmation}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/notificationsConfiguration"
                        component={NotificationsConfiguration}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        path="/settings/notificationsConfiguration/:communicationType"
                        component={NotificationsConfigurationTransports}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/fingerprintConfiguration"
                        component={FingerprintConfiguration}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/fingerprintConfiguration/deleteConfirmation"
                        component={FingerprintDeleteConfirmation}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/pushNotifications"
                        component={PushNotifications}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/myDevices"
                        component={MyDevices}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/pushNotifications/deleteConfirmation"
                        component={PushNotificationsDeleteConfirmation}
                        noBar
                    />

                    {/* MEJORAR UI */}
                    <DefaultLayout
                        exact
                        path="/settings/changeEnvironment"
                        component={ChangeEnvironment}
                        transition="transition-flow-open"
                        noBar
                    />

                    {/* MEJORAR UI */}
                    <DefaultLayout
                        exact
                        path="/settings/setDefaultEnvironment"
                        component={SetDefaultEnvironment}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeLanguage"
                        component={ChangeLanguage}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeEmail"
                        component={ChangeEmail}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changeEmail/confirmation"
                        component={ChangeEmailConfirmation}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePhone"
                        component={ChangePhone}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/changePhone/confirmation"
                        component={ChangePhoneConfirmation}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/settings/authenticatorHandler"
                        component={AuthenticatorHandler}
                        transition="transition-flow-open"
                        noBar
                    />
                    <DefaultLayout exact path="/settings/LastLogin" component={LastLogin} noBar />

                    {/* SETTINGS TOKEN */}
                    <DefaultLayout
                        path="/activateSoftToken"
                        component={SoftTokenActivationFlow}
                        transition="transition-drill-in"
                        noBar
                    />
                    <DefaultLayout
                        path="/unlockDevice"
                        component={UnlockDeviceFlow}
                        transition="transition-drill-in"
                        noBar
                    />
                    <DefaultLayout
                        path="/deactivateSoftToken"
                        component={SoftTokenDeactivationFlow}
                        transition="transition-drill-in"
                        noBar
                    />
                    <DefaultLayout
                        path="/authenticateSofttoken"
                        noBar
                        component={SoftTokenValidateOtp}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        path="/migrateSoftTokenEntrust"
                        noBar
                        component={SoftTokenValidateEntrustCode}
                        transition="transition-drill-in"
                        componentProps={{
                            title: "softtoken.validation.migrate.entrust.title.label",
                            description: "softtoken.validation.migrate.entrust.description.label",
                        }}
                    />
                    <DefaultLayout
                        path="/migrateSoftTokenLocal"
                        noBar
                        component={SoftTokenValidateEntrustCode}
                        transition="transition-drill-in"
                        componentProps={{
                            title: "softtoken.validation.migrate.local.title.label",
                            description: "softtoken.validation.migrate.local.description.label",
                        }}
                    />
                    <DefaultLayout
                        path="/createTokenPinForm"
                        noBar
                        component={CreateTokenPinForm}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        path="/auth/tokenActivationStep1"
                        noBar
                        exact
                        component={TokenActivationStep1}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        path="/auth/tokenActivationStep1RedirectSession"
                        noBar
                        exact
                        component={TokenActivationStep1}
                        transition="transition-drill-in"
                        componentProps={{
                            pathGoBack: "/desktop",
                        }}
                    />
                    <DefaultLayout
                        path="/auth/tokenActivationStep2"
                        noBar
                        component={TokenActivationStep2}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        path="/auth/tokenActivationPending"
                        noBar
                        component={TokenActivationPending}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        path="/auth/tokenActivationSuccess"
                        noBar
                        component={TokenActivationSuccess}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        path="/auth/tokenActivationFailed"
                        noBar
                        component={TokenActivationFailed}
                        transition="transition-drill-in"
                        componentProps={{
                            showDocumentButton: "1",
                        }}
                    />
                    <DefaultLayout
                        path="/auth/tokenEntrustActivationFailed"
                        noBar
                        component={TokenActivationFailed}
                        transition="transition-drill-in"
                        componentProps={{
                            showDocumentButton: "0",
                        }}
                    />

                    {/* LOANS */}
                    <DefaultLayout exact path="/loans" component={Loans} />
                    <DefaultLayout
                        exact
                        path="/loans/:id/"
                        component={LoanDetails}
                        transition="transition-drill-in"
                        defaultValidationPosition={false}
                    />
                    <DefaultLayout
                        exact
                        path="/loans/:id/setAlias"
                        component={LoanSetAlias}
                        transition="transition-flow-open"
                        defaultValidationPosition={false}
                    />

                    {/* Borrar ? */}
                    {/* <DefaultLayout path="/loansPayment/request" component={RequestLoanForm} /> */}

                    {/* Empresas */}
                    {/* <DefaultLayout path="/salaryPayment/request" component={RolePaymentForm} /> */}

                    {/* FORMS */}
                    <DefaultLayout
                        exact
                        path="/formCustom/transferInternal"
                        component={TransferInternalForm}
                        permissions={["transferInternal"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/transferLocal"
                        component={TransferLocalForm}
                        permissions={["transferLocal"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/transferForeign"
                        component={TransferForeignForm}
                        permissions={["transferForeign"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/createFixedTermDepositForm"
                        component={CreateFixedTermDepositForm}
                        permissions={["depositCreate"]}
                        noBar
                    />

                    {/* PAYMENT LISTS */}
                    <DefaultLayout
                        exact
                        path="/formCustom/payCreditCardLocal"
                        component={PayCreditCardLocal}
                        permissions={["payCreditCard"]}
                        isCreditCardPaymentForm
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/payCreditCardThird"
                        component={TransferLocalForm}
                        permissions={["payCreditCardThird"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/financingPlan"
                        component={FinancingPlanForm}
                        // permissions={["financingPlan"]}
                        permissions={["payCreditCard"]}
                        noBar
                    />
                    <DefaultLayout
                        path="/formCustom/payLoan"
                        component={LoanPaymentForm}
                        permissions={["payLoan"]}
                        noBar
                    />

                    {/* REFERENCE LETTER */}
                    <DefaultLayout exact path="/formCustom/referenceLetter" component={ReferenceLetter} noBar />

                    {/* FINANCES */}
                    <DefaultLayout exact path="/finances" component={Finances} noBar />

                    {/* RECHARGE */}
                    <DefaultLayout
                        exact
                        path="/formCustom/rechargeCreditCardLocal"
                        component={RechargeCreditCardLocal}
                        permissions={["rechargeCreditCardLocal"]}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/formCustom/rechargeCreditCardThird"
                        component={TransferLocalForm}
                        permissions={["rechargeCreditCardThird"]}
                        noBar
                    />
                    {/*
                    <DefaultLayout exact path="/formCustom/installmentPurchase" component={InstallmentPurchaseForm} />
*/}
                    {/* COMMUNICATIONS */}
                    <DefaultLayout
                        exact
                        path="/communications"
                        component={CommunicationMain}
                        noBar
                        // scrollable={false}
                    />

                    {/* TRANSACTIONS */}
                    <DefaultLayout exact path="/transactions/list" component={TransactionsList} noBar />
                    <DefaultLayout
                        exact
                        path="/transactions/list/historic"
                        component={TransactionsListHistoric}
                        noBar
                    />
                    <DefaultLayout exact path="/pendingTransaction/list" component={PendingTransactionList} noBar />
                    <DefaultLayout
                        path="/transaction/:idTransaction"
                        component={TransactionCustom}
                        transition="transition-flow-open"
                        exact
                        noBar
                    />
                    <DefaultLayout
                        path="/transaction/historic/:idTransaction"
                        component={TransactionCustom}
                        transition="transition-flow-open"
                        exact
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/transactions/scheduled/migrate"
                        component={ScheduledTransactionsMigration}
                        noBar
                    />

                    {/* EMPRESAS PAGO DE SUELDO */}
                    {/* <DefaultLayout
                        path="/transaction/:idTransaction/processDetail"
                        component={TransactionPaymentLines}
                        transition="transition-drill-in"
                    /> */}

                    {/* EMPRESAS? */}
                    {/* <DefaultLayout path="/form/:formId/bankSearch/:fieldId" component={BankSearch} /> */}
                    {/* <DefaultLayout path="/form/:formId/templates" component={Templates} /> */}
                    {/* END EMPRESAS? */}

                    {/* EXTERNAL */}
                    <DefaultLayout exact path="/termsAndConditions" component={TermsAndConditions} />

                    {/* administration */}
                    <DefaultLayout exact path="/administration/users/invite" component={UserInvite} />
                    <DefaultLayout exact path="/administration/users/inviteStep2" component={UserInviteStep2} />
                    <DefaultLayout exact path="/administration/users/inviteStep3" component={UserInviteStep3} />
                    <DefaultLayout
                        exact
                        path="/administration/medium/signature/:idTransaction/ticket"
                        component={AdministrationMediumSignatureTicket}
                    />
                    <DefaultLayout path="/administration" component={Administration} />
                    {/* end of administration */}

                    {/* beneficiary */}
                    <DefaultLayout
                        exact
                        path="/frequentDestination"
                        component={ListBeneficiary}
                        permissions={["beneficiaryManage"]}
                        noBar
                    />
                    <DefaultLayout exact path="/createFrequentDestination" component={CreateBeneficiary} noBar />
                    <DefaultLayout
                        exact
                        path="/viewFrequentDestination/:idBeneficiary"
                        component={ViewBeneficiary}
                        defaultValidationPosition={false}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/editFrequentDestination/:beneficiaryId/:type"
                        component={CreateBeneficiary}
                        defaultValidationPosition={false}
                        noBar
                    />
                    <DefaultLayout
                        exact
                        path="/detailModifyDeleteFrequentDestination"
                        component={DetailModiyDeleteFrequentDestinatio}
                        noBar
                    />
                    {/* end of beneficiary */}

                    {/* EMPRESAS? */}
                    {/* <DefaultLayout
                        path="/detailModifyDeleteFrequentDestination/bankSearch/:fieldId"
                        component={BankSearchCustom}
                    /> */}

                    <DefaultLayout exact path="/envGeneralConditions" component={GeneralConditionEnvironment} />

                    {/* For develop */}
                    <DefaultLayout path="/ui-components" component={UiComponents} />

                    <DefaultLayout exact path="/requestsAndComplaints" component={RequestsAndComplaints} noBar />
                    <DefaultLayout
                        exact
                        path="/internationalTransferTracking"
                        component={InternationalTransferTracking}
                        noBar
                    />
                    <DefaultLayout exact path="/error-page" component={ErrorPage} />

                    <DefaultLayout exact path="/deviceRequired" component={DeviceRequired} noBar />
                    {/* WALLY FUNCTIONS */}
                    {isMobileNative && (
                        <>
                            {/* WALLY ENROLLMENT */}
                            <DefaultLayout
                                path="/enrollmentWally"
                                noBar
                                component={EnrollmentWally}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/enrollmentWallySelectAccount"
                                noBar
                                component={EnrollmentWallySelectAccount}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/enrollmentWallyFinish"
                                noBar
                                component={EnrollmentWallyFinish}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/wally"
                                component={OneTouchAccessWally}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout path="/sendWally" component={SendWally} transition="transition-drill-in" />

                            <DefaultLayout
                                path="/sendWallyAddContact"
                                noBar
                                component={SendWallyAddContact}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/sendWallyRequestAddContact"
                                noBar
                                component={SendWallyAddContact}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/sendWallyTransaction"
                                noBar
                                component={SendWallyTransaction}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/sendWallyRequestTransaction"
                                noBar
                                component={SendWallyTransaction}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/requestWally"
                                component={RequestWally}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/lastMovementsWally"
                                component={LastMovementsWally}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/transactionWally"
                                noBar
                                component={SendWallyTransaction}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/transactionPendingWally"
                                noBar
                                component={SendWallyTransaction}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/pendingWally"
                                component={PendingWally}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/settingsWally"
                                component={SettingsWally}
                                transition="transition-drill-in"
                                exact
                            />
                            <DefaultLayout
                                path="/changePhoneNumberStep1"
                                noBar
                                component={ChangePhoneNumberStep1}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/changePhoneNumberStep2"
                                noBar
                                component={ChangePhoneNumberStep2}
                                transition="transition-drill-in"
                            />

                            <DefaultLayout
                                path="/changeAccountStep1"
                                noBar
                                component={ChangeAccountStep1}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/deactivationWally"
                                noBar
                                component={DeactivationWally}
                                transition="transition-drill-in"
                            />
                            <DefaultLayout
                                path="/auth/tokenActivationAttemps"
                                noBar
                                component={TokenActivationAttemps}
                                transition="transition-drill-in"
                                componentProps={{
                                    showDocumentButton: "1",
                                }}
                            />
                            <DefaultLayout
                                path="/auth/tokenActivationFailedDocument"
                                noBar
                                component={TokenActivationFailedDocument}
                                transition="transition-drill-in"
                                componentProps={{
                                    showDocumentButton: "1",
                                }}
                            />
                        </>
                    )}
                    <Redirect from="*" to="/desktop" />
                </Switch>
                <BackButtonListener />
                <Route path="/" component={SessionAboutToExpire} />
                <Route path="/" component={SessionCLose} />
                <Route path="/" component={ProductRequestModal} />
                <Route path="/" component={AccountRequestModal} />
                <Route path="/" component={ConfirmationModal} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    permissionInsertQuestions: sessionSelectors.hasPermissions(state, [INSERT_SECURITY_QUESTION]),
    permissionUpdateQuestions: sessionSelectors.hasPermissions(state, [UPDATE_SECURITY_QUESTION]),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(PrivateRoutes)));
