import { PERMISSION_CREDIT_CARD_STATEMENT } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import SideBarModal from "pages/_components/modal/SideBarModal";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import PaginatedTable from "pages/_components/PaginatedTable/PaginatedTable";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import ProductDetail from "pages/_components/ProductDetail";
import DetailHeadInfo from "pages/creditCards/_components/DetailHeadInfo";
import { array, arrayOf, bool, func, instanceOf, number as int, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import * as configUtils from "util/config";
import { CREDIT_CARD_RELATION_TYPE_ADDITIONAL, generatePinAssigmentData, MAP_CREDIT_CARD_TYPE } from "util/creditCards";
import * as dateUtils from "util/date";
import { isIOSPlatform, isMobileNativeFunc, isSupportVersion } from "util/device";
import * as i18nUtils from "util/i18n";
import {
    applePayCardWasDigitized,
    applePayRemovePassButton,
    MINIMUM_VERSION_IOS_SUPPORTED,
    PROVISION_SUCCESS_CODE,
    START_EVENTS_CODE,
} from "util/wallet/wallet.util";
import { CreditCardStatus } from "../_components/CreditCard/CreditCard";
import StatementLines from "../accounts/StatementLines";
import AdditionalsFilter from "./_components/AdditionalsFilter";
import CreditCardAdditionalList from "./_components/CreditCardAdditionalList";
import CreditCardDeferred from "./_components/CreditCardDeferred";
import Movement from "./_components/Movement";
import MovementHeader from "./_components/MovementHeader";
import MovementsFilter from "./_components/MovementsFilter";

const TRANSFER_INTERNAL_PERMISSION = "transferInternal";
const TRANSFER_LOCAL_PERMISSION = "transferLocal";
const TRANSFER_FOREIGN_PERMISSION = "transferForeign";
const PRODUCT_TYPE_CREDIT_CARD = "TC";

const PAGE_IDENTIFIER = "creditCards.read";

const INICIAL_FILTER_STATE = {
    dateFrom: dateUtils.getDateWithDaysSubtract(5),
    dateTo: new Date(),
    lastMovements: true,
    cardNumber: "",
};

class CreditCardDetails extends Component {
    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
    };

    state = {
        selectedFilter: "currentPeriod",
        resetDateFilters: false,
        showSideBarDownloadDetail: false,
        downloadingStatements: false,
        downloadingDeferred: false,
        downloadingAdditionals: false,
        showSideBarSearchStatements: false,
        transitMovementSize: 0,
        showEnabledWallet: false,
        showWallet: false,
        isCardWasDigitized: undefined,
        showMovFilters: false,
        showAdditionalsFilters: false,
        filters: INICIAL_FILTER_STATE,
        hasFiltersApplied: false,
        additionalCards: array,
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
    };

    componentWillMount() {
        const {
            dispatch,
            match: { params },
        } = this.props;
        dispatch(creditCardActions.readCreditCard(params?.id));
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(productActions.resetChangedPoints());
        dispatch(productActions.hideExchangePointForm());
        // this.loadCreditCars();
    }

    componentDidUpdate(prevProps) {
        const { dispatch, creditCards, getSelectProduct, isMobile } = this.props;

        if (prevProps.creditCards !== creditCards && creditCards.length > 0) {
            const tmpCreditCard = creditCards.filter((card) => card.idProduct === getSelectProduct?.idProduct);
            if (tmpCreditCard.length > 0) {
                dispatch(creditCardActions.setExtraDataDetail(tmpCreditCard[0]));
            }
        }
        if (
            prevProps.match.params.id !== this.props.match.params.id &&
            prevProps.match.path === "/creditCardAdditional/:id"
        ) {
            this.loadCreditCars();
        }
        if (this.props.match.params.id !== getSelectProduct?.idProduct && !isMobile) {
            const tmpCreditCard = creditCards.filter((card) => card.idProduct === this.props.match.params.id);
            if (tmpCreditCard.length > 0) {
                dispatch(creditCardActions.setExtraDataDetail(tmpCreditCard[0]));
                dispatch(
                    productActions.setSelectProduct({
                        idProduct: this.props.match.params.id,
                        description: tmpCreditCard[0].description,
                        statusCode: tmpCreditCard[0].statusCode,
                        type: tmpCreditCard[0].relationType,
                    }),
                );
            }
        }
        if (!this.props.history.location?.pathname.includes(getSelectProduct?.idProduct) && isMobile) {
            const url = this.props.history.location?.pathname;
            const splitUrl = url.split("/");
            if (splitUrl.length > 2) {
                const tmpCreditCard = creditCards.filter((card) => card.idProduct === splitUrl[2]);
                if (tmpCreditCard.length > 0) {
                    dispatch(creditCardActions.setExtraDataDetail(tmpCreditCard[0]));
                    dispatch(
                        productActions.setSelectProduct({
                            idProduct: tmpCreditCard[0].idProduct,
                            description: tmpCreditCard[0].description,
                            statusCode: tmpCreditCard[0].statusCode,
                            type: tmpCreditCard[0].relationType,
                        }),
                    );
                }
            }
        }

        if (this.props.creditCard && this.props.creditCard !== prevProps.creditCard) {
            this.validateCreditCardWallet();
        }
    }

    validateEnrolledWallet = async () => {
        const { creditCard } = this.props;
        if (!creditCard?.panSuffix) {
            this.setState({ showWallet: false });
            return;
        }

        const cardWasDigitizedResp = await applePayCardWasDigitized(creditCard.panSuffix);

        const isCardWasDigitized =
            cardWasDigitizedResp !== undefined && (cardWasDigitizedResp === true || cardWasDigitizedResp === "true");
        this.setState({ showWallet: true, isCardWasDigitized });
    };

    validateCreditCardWallet = () => {
        if (!isMobileNativeFunc() || !isIOSPlatform()) {
            this.setState({ showWallet: false });
            return;
        }

        const versionResult = isSupportVersion(window.device.version, MINIMUM_VERSION_IOS_SUPPORTED);
        if (versionResult === undefined || versionResult === false || versionResult === "false") {
            this.setState({ showWallet: false });
            return;
        }

        /**
         * if config key not exists, get default value true for show only certain users
         */
        const testEnabled = configUtils.getBoolean("applePay.test.enabled", true);
        if (testEnabled === false) {
            this.validateEnrolledWallet();
            return;
        }

        const { loggedUser } = this.props;
        const userTestEnabled = configUtils.getArray("applePay.test.users");
        if (userTestEnabled.some((item) => item && loggedUser?.userId && item === loggedUser.userId)) {
            this.validateEnrolledWallet();
        }
    };

    loadCreditCars = () => {
        const { match, dispatch, extraDataDetail } = this.props;
        const [dateFrom, dateTo] = [null, null];

        if (!extraDataDetail) {
            dispatch(
                routerActions.replace({ pathname: "/creditCards", state: { transition: "transition-flow-close" } }),
            );
            return;
        }

        const selectedFilter = "currentPeriod";
        this.setState({ selectedFilter });

        dispatch(creditCardActions.listRequest());

        dispatch(
            creditCardActions.detailRequest(match.params.id, {
                dateFrom,
                dateTo,
            }),
        );
    };

    showAdditional = (creditCard) => {
        if (!creditCard) {
            return;
        }

        const { dispatch, isMobile } = this.props;
        const { idProduct, description, statusCode } = creditCard;

        dispatch(creditCardActions.setExtraDataDetail(creditCard));
        dispatch(productActions.setSelectProduct({ idProduct, description, statusCode, type: "N" }));
        if (!isMobile) {
            dispatch(creditCardActions.detailRequest(idProduct, {}));
        }
        this.props.history.push({ pathname: `/creditCardAdditional/${idProduct}` });
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.showOptions());
    };

    handleFilterButtonsClick = (selectedFilter, idCreditCard) => {
        const { dispatch } = this.props;
        const [dateFrom, dateTo] = this.periods[selectedFilter];

        this.setState({ selectedFilter });
        this.handleResetDateFilters(true);
        dispatch(creditCardActions.movementsRequest(idCreditCard, { dateFrom, dateTo }));
    };

    onFinishDownload = () => {
        this.setState({
            downloadingStatements: false,
            downloadingDeferred: false,
            downloadingAdditionals: false,
        });
    };

    handleClickDownload = (format, type) => {
        const { dispatch, match, filters, creditCard, creditCardDetail } = this.props;
        if (type == "Movements") {
            this.setState({
                downloadingStatements: true,
            });
            dispatch(
                creditCardActions.downloadMovements(
                    match.params.id,
                    { dateFrom: filters?.dateFrom, dateTo: filters?.dateTo, selectedDate: filters?.selectedDate },
                    format,
                    this.onFinishDownload,
                    {
                        numberMask: creditCard?.numberMask,
                        cardStyle: creditCard?.cardStyle,
                        nameOnCard: creditCard?.nameOnCard,
                        availableBalance: creditCardDetail?.availableQuota,
                        currentDue: creditCard?.currentDue,
                        minimumPayment: creditCardDetail?.minimumPayment
                    },
                ),
            );
        }
        if (type == "Deferred") {
            this.setState({
                downloadingDeferred: true,
            });
            dispatch(
                creditCardActions.downloadDeferred(
                    match.params.id,
                    { dateFrom: filters?.dateFrom, dateTo: filters?.dateTo, selectedDate: filters?.selectedDate },
                    format,
                    this.onFinishDownload,
                    { numberMask: creditCard?.numberMask, cardStyle: creditCard?.cardStyle, nameOnCard : creditCard?.nameOnCard},
                ),
            );
        }
        if (type == "Additionals") {
            this.setState({
                downloadingAdditionals: true,
            });
            dispatch(
                creditCardActions.downloadAdditionals(
                    match.params.id,
                    { cardNumber: this.state.filters?.cardNumber },
                    format,
                    this.onFinishDownload,
                    { numberMask: creditCard?.numberMask, cardStyle: creditCard?.cardStyle },
                ),
            );
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.hideOptions());
        dispatch(routerActions.goBack());
    };

    handleResetDateFilters = (resetDateFilters) => this.setState({ resetDateFilters });

    isAdditionalCreditCard = () => {
        const { extraDataDetail } = this.props;
        if (!extraDataDetail) {
            return false;
        }
        const { relationType } = extraDataDetail;
        return relationType && relationType === CREDIT_CARD_RELATION_TYPE_ADDITIONAL;
    };

    isShowConditions = (isCorporative) => {
        if (isCorporative) {
            return true;
        }
        return !this.isAdditionalCreditCard();
    };

    hasAdditionalCreditCardList = () => {
        const { extraDataDetail } = this.props;
        if (!extraDataDetail) {
            return false;
        }

        const { additionalCreditCardList } = extraDataDetail;
        return (
            additionalCreditCardList &&
            additionalCreditCardList?.filter((creditCard) => creditCard.isVisibleInWidget).length > 0
        );
    };

    getAdditionalCreditCardList = () => {
        const { extraDataDetail } = this.props;
        if (!extraDataDetail) {
            return [];
        }
        const { additionalCreditCardList } = extraDataDetail;
        return additionalCreditCardList
            ?.filter((creditCard) => creditCard.isVisibleInWidget)
            ?.map((item) => {
                const artifactType = MAP_CREDIT_CARD_TYPE.get(item?.artifactType || "");
                return {
                    ...item,
                    iconType: artifactType?.icon || "",
                    type: artifactType?.title || "",
                    brand: (item?.franchise || "").toLowerCase(),
                    alias: item?.label || "",
                    avaible: item?.availableBalance || 0,
                };
            });
    };

    getOptionsByRelationType = (optionList, relationType) =>
        optionList?.filter((item) => item && item[relationType] && item[relationType] === true);

    handlePinAssigment = () => {
        const { dispatch, creditCard } = this.props;
        const values = generatePinAssigmentData({
            idProduct: creditCard.idProduct,
            numberMask: creditCard.numberMask,
            shortLabel: creditCard.shortLabel,
            franchise: creditCard.franchise,
            expirationDate: creditCard.expirationDateMask,
            submitAction: creditCardActions.assignPinRequest,
        });

        dispatch(changeStatusProductActions.modalShow(values));
    };

    filterOptionListByPermission = (optionList) => {
        const { activeEnvironment } = this.props;
        if (!activeEnvironment?.permissions) {
            return [];
        }
        return (
            optionList?.filter((item) => {
                if (!item || !item.permissionList) {
                    return false;
                }
                return item.permissionList.some(
                    (itemP) =>
                        itemP === "*" ||
                        (Object.keys(activeEnvironment.permissions).includes(itemP) &&
                            activeEnvironment.permissions[itemP] &&
                            activeEnvironment.permissions[itemP] === true),
                );
            }) || []
        );
    };

    handlePointsClick = () => {
        const { dispatch, getSelectProduct } = this.props;
        if (getSelectProduct?.statusCode === CreditCardStatus.Blocked) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("forms.product.exchange.points.text.blocked.error"),
                    "error",
                    ["creditcards", "creditCardDetails"],
                ),
            );
        } else if (getSelectProduct?.statusCode === CreditCardStatus.Inactived) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("forms.product.exchange.points.text.inactive.error"),
                    "error",
                    ["creditcards", "creditCardDetails"],
                ),
            );
        } else if (getSelectProduct?.statusCode !== CreditCardStatus.Actived) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("forms.product.exchange.points.text.nonactive.error"),
                    "error",
                    ["creditcards", "creditCardDetails"],
                ),
            );
        } else {
            dispatch(productActions.showExchangePointForm());
        }
    };

    showEnableWalletRequest = (creditCardId) => {
        if (!creditCardId) {
            return;
        }

        this.setState({ showEnabledWallet: true });
    };

    callbackProvision = (resultCode) => {
        if (!resultCode || resultCode === START_EVENTS_CODE) {
            return;
        }
        this.setState({ showEnabledWallet: false });
        applePayRemovePassButton();

        if (resultCode === PROVISION_SUCCESS_CODE) {
            this.setState({ isCardWasDigitized: true });
        }
    };

    handleShowFilterClick = () => {
        this.setState({
            showMovFilters: true,
        });
    };

    handleShowAdditionalFilterClick = () => {
        this.setState({
            showAdditionalsFilters: true,
        });
    };

    handleClearFilterClick = () => {
        const { dispatch } = this.props;
        this.setState({
            filters: INICIAL_FILTER_STATE,
            hasFiltersApplied: false,
        });

        dispatch(creditCardActions.clearFilters());
    };

    handleClearAdditionalFilterClick = () => {
        const { dispatch, creditCard } = this.props;
        const { idProduct } = creditCard;
        this.setState({ hasFiltersApplied: false, filters: INICIAL_FILTER_STATE });
        dispatch(creditCardActions.additionalCreditCardRequest(idProduct, "additionals"));
    };

    handleFilterClick = (values) => {
        const { dispatch } = this.props;
        const filterValues = { ...values };
        const { selectedDate } = filterValues;
        const [month, year] = selectedDate.split("/");

        filterValues.dateFrom = dateUtils.getFirstDayOfMonth(parseInt(year, 10), parseInt(month, 10));
        filterValues.dateTo = new Date();
        filterValues.lastMovements = false;
        dispatch(creditCardActions.setPaginatedInfo(1, undefined, undefined, filterValues));

        this.setState({
            filters: filterValues,
            hasFiltersApplied: true,
            showMovFilters: false,
        });
    };

    handleFilterAddiciontalClick = (values) => {
        const { dispatch, additionalCreditCards } = this.props;

        const filteredAdditionalCards = additionalCreditCards.filter(
            (creditCard) => creditCard.number?.toLowerCase().includes(values),
            // creditCard.label?.toLowerCase().includes(values) ||
            // creditCard.shortLabel?.toLowerCase().includes(values) ||
            // creditCard.cardStyle?.toLowerCase().includes(values) ||
            // creditCard.nameOnCard?.toLowerCase().includes(values),
        );

        this.setState({
            hasFiltersApplied: true,
            showAdditionalsFilters: false,
            filters: { ...this.state, cardNumber: values },
        });
        dispatch(creditCardActions.additionalCreditCardSuccess(filteredAdditionalCards));
    };

    renderDownloadMovementDocs = (disabled) => {
        const { downloadingStatements } = this.state;
        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf", "Movements"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls", "Movements"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt", "Movements"),
            },
        ];

        return (
            <Dropdown
                image="images/util/arrow-down.svg"
                label="global.download"
                buttonClass="btn-download"
                fetching={downloadingStatements}
                imageRight
                disabled={disabled}
                pullRight>
                {documents.map((item) => (
                    <Button
                        key={item.label}
                        label={item.label}
                        onClick={() => {
                            item.onClick();
                        }}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    };

    renderDownloadDeferredDocs = (disabled) => {
        const { downloadingDeferred } = this.state;
        // if (!downloadingMovements) {
        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf", "Deferred"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls", "Deferred"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt", "Deferred"),
            },
        ];

        return (
            <Dropdown
                image="images/util/arrow-down.svg"
                label="global.download"
                buttonClass="btn-download"
                fetching={downloadingDeferred}
                imageRight
                disabled={disabled}
                pullRight>
                {documents.map((item) => (
                    <Button
                        key={item.label}
                        label={item.label}
                        onClick={() => {
                            item.onClick();
                        }}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    };

    renderDownloadAdditionalsDocs = (disabled) => {
        const { downloadingAdditionals } = this.state;
        // if (!downloadingMovements) {
        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf", "Additionals"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls", "Additionals"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt", "Additionals"),
            },
        ];

        return (
            <Dropdown
                image="images/util/arrow-down.svg"
                label="global.download"
                buttonClass="btn-download"
                fetching={downloadingAdditionals}
                imageRight
                disabled={disabled}
                pullRight>
                {documents.map((item) => (
                    <Button
                        key={item.label}
                        label={item.label}
                        onClick={() => {
                            item.onClick();
                        }}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    };

    render() {
        const {
            creditCard,
            creditCardDetail,
            isDesktop,
            dispatch,
            isFetching,
            totalFavorites,
            getSelectProduct,
            currentLang,
            extraDataDetail,
            activeEnvironment,
            showExchangePointForm,
            changedPoints,
            history,
            creditCardInList,
            isTablet,
            currentPage,
            paginatedDataMap,
            itemsMap,
            permissionStatement,
            isAditional,
            additionalCreditCards,
            isFetchingAdditionals,
            isfetchingToken,
        } = this.props;
        const {
            transitMovementSize,
            downloadingStatements,
            downloadingDeferred,
            downloadingAdditionals,
            showSideBarSearchStatements,
            showSideBarDownloadDetail,
            showMovFilters,
            showAdditionalsFilters,
            filters,
            hasFiltersApplied,
            rowsPerPage,
        } = this.state;

        const secondaryBtn = () => {
            const secondaryBtnArr = [
                {
                    bsStyle: "outline",
                    label: "creditCard.buttons.statements",
                    onClick: () => {
                        this.setState({ showSideBarDownloadDetail: true });
                    },
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                },
            ];

            if (isDesktop) {
                const btnPayCard = {
                    bsStyle: "primary",
                    label: creditCard?.isPrepaid
                        ? "creditCard.buttons.reloadCard.contextMenu"
                        : "creditCard.buttons.payCard",
                    href: creditCard?.isPrepaid
                        ? "/formCustom/rechargeCreditCardLocal?creditCard="
                        : `/formCustom/payCreditCardLocal?creditCard=`,
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                };
                secondaryBtnArr.push(btnPayCard);
            }
            return secondaryBtnArr;
        };

        const contextOptions = () => {
            const contextOptionsBtnArr = [];
            contextOptionsBtnArr.push({
                label: "creditCard.buttons.pin",
                showInfAdditional: true,
                showInfMain: true,
                onClick: () => {
                    if (getSelectProduct.statusCode === CreditCardStatus.Actived) {
                        this.handlePinAssigment();
                    } else {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("creditCards.snackbar.assignPin.disabled"),
                                "error",
                                ["creditcards", "creditCardDetails"],
                            ),
                        );
                    }
                },
                permissionList: ["*"],
            });
            if (creditCardInList?.showReplaceOption) {
                contextOptionsBtnArr.push({
                    label: "creditCard.buttons.report.replace.card",
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                    href: "/formCustom/reportReplaceCard/",
                });
            }
            if (creditCardInList?.showRenewOption) {
                contextOptionsBtnArr.push({
                    label: "creditCard.buttons.report.renew.card",
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                    href: "/formCustom/renewCard/",
                });
            }

            contextOptionsBtnArr.push({
                label: "creditCard.buttons.financing.plans",
                showInfAdditional: false,
                showInfMain: true,
                permissionList: ["*"],
                href: `/creditCards/financingplans/`,
            });
            /* contextOptionsBtnArr.push({
                label: "creditCard.buttons.lostCard",
                showInfAdditional: true,
                showInfMain: true,
                permissionList: ["*"],
            });
            contextOptionsBtnArr.push({
                label: creditCard?.isPrepaid ? "creditCard.buttons.payServices" : "creditCard.buttons.buyBalance",
                showInfAdditional: false,
                showInfMain: true,
                permissionList: ["*"],
            }); */
            contextOptionsBtnArr.push({
                label: "creditCard.buttons.payServices",
                showInfAdditional: true,
                showInfMain: true,
                permissionList: ["*"],
                onClick: () => {
                    history.push("/servicePayments");
                },
            });
            contextOptionsBtnArr.push({
                label: "creditCard.buttons.transfer",
                showInfAdditional: true,
                showInfMain: false,
                permissionList: [TRANSFER_INTERNAL_PERMISSION, TRANSFER_LOCAL_PERMISSION, TRANSFER_FOREIGN_PERMISSION],
                onClick: () => {
                    if (!activeEnvironment?.permissions) {
                        return;
                    }
                    const permissionsList = Object.keys(activeEnvironment.permissions);
                    let pathTransfer;
                    if (permissionsList.includes(TRANSFER_INTERNAL_PERMISSION)) {
                        pathTransfer = "/formCustom/transferInternal";
                    } else if (permissionsList.includes(TRANSFER_LOCAL_PERMISSION)) {
                        pathTransfer = "/formCustom/transferLocal";
                    } else if (permissionsList.includes(TRANSFER_FOREIGN_PERMISSION)) {
                        pathTransfer = "/formCustom/transferForeign";
                    }

                    if (pathTransfer) {
                        dispatch(
                            routerActions.replace({
                                pathname: pathTransfer,
                                state: { transition: "transition-flow-close" },
                            }),
                        );
                    }
                },
            });

            return contextOptionsBtnArr;
        };

        let contextOptionList = [];
        if (isDesktop) {
            contextOptionList = this.getOptionsByRelationType(
                contextOptions(),
                this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
            );
        } else {
            contextOptionList = this.getOptionsByRelationType(
                secondaryBtn(),
                this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
            ).concat(
                this.getOptionsByRelationType(
                    contextOptions(),
                    this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
                ),
            );
        }

        contextOptionList = this.filterOptionListByPermission(contextOptionList);

        let secondaryButtonList = this.getOptionsByRelationType(
            secondaryBtn(),
            this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
        );

        secondaryButtonList = this.filterOptionListByPermission(secondaryButtonList);

        const hasStatements = additionalCreditCards.some((creditCard) => creditCard.statements?.length > 0);

        return (
            <>
                <Notification scopeToShow="creditCardDetails" />
                <PageLoading loading={isFetching} classicStyle={false}>
                    {!isFetching && creditCard && creditCardDetail && (
                        <>
                            <Notification scopeToShow="creditCard/details" />
                            <ProductDetail>
                                <ProductDetail.Header
                                    // contextOptions={contextOptionList}
                                    // handleClickEditAlias={this.handleClickEditAlias}
                                    dispatch={dispatch}
                                    handleOptionsClick={this.handleClick}
                                    isDesktop={isDesktop}
                                    onBack={this.handleBack}
                                    product={creditCard}
                                    // idProduct={creditCard?.idProduct}
                                    productId={creditCard?.idProduct}
                                    // secondaryBtn={secondaryButtonList}
                                    totalFavorites={totalFavorites}
                                    selectProduct={getSelectProduct}
                                    textBack="creditCard.detail.returnToList"
                                    renderProductTitle={false}
                                    productStatus={`creditCard.status.additional.${creditCard?.isAditional}`}
                                />
                                <ProductDetail.Body
                                    id="productDetail.body"
                                    closeMoreFiltersWhenSiblingClick
                                    isDesktop={isDesktop}
                                    product={creditCard}
                                    productKind="creditCards"
                                    filters={[]}
                                    {...(creditCard.isTherePendingTransaction && {
                                        disclaimerStyled: "warning",
                                        disclaimerLabelkey: "accounts.disclaimer.pendingTransaction",
                                    })}
                                    {...{
                                        detailHeadInfo: (
                                            <DetailHeadInfo
                                                {...this.props}
                                                creditCard={creditCardDetail}
                                                status={creditCard.status}
                                                productStatus={`creditCard.status.additional.${creditCard?.isAditional}`}
                                            />
                                        ),
                                    }}
                                    onSelect={() => this.handleClearFilterClick()}>
                                    <Box
                                        isDesktop={isDesktop}
                                        iconLabel={!creditCard?.isAditional && "images/icons/movements.svg"}
                                        keyLabel="creditCard.statements"
                                        isAditional={creditCard?.isAditional}>
                                        <PaginatedTable
                                            idActivity="creditCards.listStatements"
                                            name="account-movs"
                                            idProduct={creditCard.idProduct}
                                            headerProps={{
                                                currency: creditCard.currency,
                                            }}
                                            itemComponent={Movement}
                                            headerComponent={MovementHeader}
                                            filters={filters}
                                            hasFiltersApplied={hasFiltersApplied}
                                            isDesktop={isDesktop}
                                            isTablet={isTablet}
                                            withoutItemsMessage={i18nUtils.get("product.table.error")}
                                            noMoreDataMessage={i18nUtils.get("creditCards.movements.noMoreMovements")}
                                            currentPage={currentPage}
                                            paginatedDataMapBKP={paginatedDataMap}
                                            itemsMapBKP={itemsMap}
                                            idExportActivity="creditCards.detail.download"
                                            handleShowFilter={this.handleShowFilterClick}
                                            handleClearFilter={this.handleClearFilterClick}
                                            renderDownloadDocs={this.renderDownloadMovementDocs}
                                            idFieldName="idCreditCard"
                                            notificationScopes={["creditCard/details"]}
                                            // autoClearWhenUnmounted={true}
                                        />
                                    </Box>

                                    {!creditCard?.isAditional && (
                                        <Box
                                            iconLabel="images/icons/credit-card-out.svg"
                                            isDesktop={isDesktop}
                                            keyLabel="creditCard.creditCardAditionals">
                                            <PaginatedDataTable
                                                handleOpen={() => {
                                                    const { creditCard } = this.props;
                                                    const { idProduct } = creditCard;
                                                    dispatch(creditCardActions.additionalCreditCardRequest(idProduct, "additionals"));
                                                }}
                                                nameList="creditCards"
                                                data={additionalCreditCards}
                                                hasFilterApplied="falsex"
                                                rowsPerPage={rowsPerPage}
                                                showFilterModal="true"
                                                hasFiltersApplied={hasFiltersApplied}
                                                filterAction={this.handleShowAdditionalFilterClick}
                                                renderDownloadDocs={this.renderDownloadAdditionalsDocs}
                                                component={CreditCardAdditionalList}
                                                hasTooltip="true"
                                                textTooltip={i18nUtils.get(
                                                    "creditCard.detail.statement.header.tooltip",
                                                    "",
                                                )}
                                                isDesktop={isDesktop}
                                                // handleShowFilter={this.handleShowFilterClick}
                                                handleClearFilter={this.handleClearAdditionalFilterClick}
                                                // renderDownloadDocs={this.renderDownloadMovementDocs}
                                                isFetching={isFetchingAdditionals}
                                            />
                                        </Box>
                                    )}
                                    {!creditCard?.isAditional && (
                                        <Box
                                            iconLabel="images/icons/currency.svg"
                                            isDesktop={isDesktop}
                                            keyLabel="creditCard.diferred">
                                            <PaginatedDataTable
                                                handleOpen={() => {
                                                    const { creditCard } = this.props;
                                                    const { idProduct } = creditCard;
                                                    dispatch(creditCardActions.additionalCreditCardRequest(idProduct, "deferred"));
                                                }}
                                                nameList="creditCards"
                                                data={hasStatements?additionalCreditCards:[]}
                                                hasFilterApplied="false"
                                                rowsPerPage={rowsPerPage}
                                                showFilterModal="true"
                                                hasFiltersApplied={hasFiltersApplied}
                                                filterAction={hasStatements?this.handleShowAdditionalFilterClick:false}
                                                renderDownloadDocs={hasStatements?this.renderDownloadDeferredDocs:false}
                                                component={CreditCardDeferred}
                                                hasTooltip={creditCard.productType === PRODUCT_TYPE_CREDIT_CARD ? false : hasStatements}
                                                textTooltip={i18nUtils.get(
                                                    "creditCard.detail.statement.header.tooltip",
                                                    "",
                                                )}
                                                isDesktop={isDesktop}
                                                // handleShowFilter={this.handleShowFilterClick}
                                                handleClearFilter={hasStatements?this.handleClearAdditionalFilterClick:false}
                                                // renderDownloadDocs={this.renderDownloadMovementDocs}
                                                isFetching={isFetchingAdditionals}
                                            />
                                        </Box>
                                    )}
                                    {permissionStatement && !creditCard?.isAditional && (
                                        <Box
                                            isDesktop={isDesktop}
                                            iconLabel="images/icons/content.svg"
                                            keyLabel="creditCard.accountStatements">
                                            <StatementLines
                                                idAccount={creditCard.idProduct}
                                                openingDate={creditCard.openingDate}
                                                isDesktop={isDesktop}
                                                productType={creditCard.productType}
                                                creditCard={creditCard}
                                                creditCardDetail={creditCardDetail}
                                                isfetchingToken={isfetchingToken}
                                            />
                                        </Box>
                                    )}
                                </ProductDetail.Body>
                            </ProductDetail>
                        </>
                    )}
                </PageLoading>
                <SideBarModal
                    show={showSideBarDownloadDetail}
                    onClose={() => {
                        this.setState({ showSideBarDownloadDetail: false });
                    }}
                    title="forms.product.state.download.title"
                />
                <SideBarModal
                    show={showMovFilters}
                    onClose={() => {
                        this.setState({ showMovFilters: false });
                    }}
                    title="global.filter">
                    <MovementsFilter
                        initialData={filters}
                        handleFilter={this.handleFilterClick}
                        handleCancel={() => {
                            this.setState({ showMovFilters: false });
                        }}
                    />
                </SideBarModal>
                <SideBarModal
                    show={showAdditionalsFilters}
                    onClose={() => {
                        this.setState({ showAdditionalsFilters: false });
                    }}
                    title="global.filter">
                    <AdditionalsFilter
                        initialData={filters}
                        handleFilter={this.handleFilterAddiciontalClick}
                        handleCancel={() => {
                            this.setState({ showAdditionalsFilters: false });
                        }}
                    />
                </SideBarModal>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;

    return {
        creditCardDetail: creditCardSelectors.getCreditCardDetail(state),
        creditCard: creditCardSelectors.getDetailInList(state, id),
        additionalCreditCards: creditCardSelectors.getAdditionalCreditCards(state),
        // creditCardInList: creditCardSelectors.getDetailInList(state, id),
        isFetching: creditCardSelectors.getFetching(state),
        filters: creditCardSelectors.getFilters(state),
        totalFavorites: creditCardSelectors.getTotalFavorites(state),
        getSelectProduct: productSelectors.getSelectProduct(state),
        currentLang: i18nSelectors.getLang(state),
        extraDataDetail: creditCardSelectors.getExtraDataDetail(state),
        creditCards: creditCardSelectors.getList(state),
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
        showExchangePointForm: productSelectors.getShowExchangePointForm(state),
        changedPoints: productSelectors.getChangedPoints(state),
        loggedUser: sessionSelectors.getUser(state),
        currentPage: creditCardSelectors.getCurrentPage(state),
        paginatedDataMap: creditCardSelectors.getPaginatedDataMap(state),
        itemsMap: creditCardSelectors.getItemsMap(state),
        permissionStatement: sessionSelectors.hasPermissions(state, [PERMISSION_CREDIT_CARD_STATEMENT]),
        isFetchingAdditionals: creditCardSelectors.isFetchingAdditionals(state),
        isfetchingToken: creditCardSelectors.isfetchingToken(state),
    };
};

CreditCardDetails.propTypes = {
    creditCard: shape({}),
    additionalCreditCards: shape({}),
    creditCardInList: shape({}),
    match: shape({}).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    // filters: shape({}).isRequired,
    totalFavorites: int.isRequired,
    getSelectProduct: shape({}),
    currentLang: string,
    extraDataDetail: shape({}),
    creditCards: arrayOf(shape({})),
    activeEnvironment: shape({}),
    showExchangePointForm: bool.isRequired,
    changedPoints: int,
    isMobile: bool,
    history: shape({}),
    loggedUser: shape({}),
    isTablet: bool.isRequired,
    currentPage: int,
    paginatedDataMap: instanceOf(Map),
    itemsMap: instanceOf(Map),
    permissionStatement: bool.isRequired,
};

CreditCardDetails.defaultProps = {
    creditCard: {},
    creditCardInList: {},
    getSelectProduct: undefined,
    currentLang: "",
    extraDataDetail: {},
    creditCards: [],
    activeEnvironment: { permissions: [] },
    changedPoints: undefined,
    isMobile: false,
    history: {},
    loggedUser: undefined,
    currentPage: undefined,
    paginatedDataMap: undefined,
    itemsMap: undefined,
};

export default connect(mapStateToProps)(CreditCardDetails);
