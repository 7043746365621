import Box from "pages/_components/Box";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import Step1Content from "pages/login/_components/Step1Content";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectors as assistantSelectors } from "reducers/assistant";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import LoginWrapper from "./_components/LoginWrapper";
import PendingInvitationModal from "./_components/PendingInvitationModal";

const LoginStep1 = (props) => {
    const {
        dispatch,
        hasActiveSession,
        isDesktop,
        isMobileNative,
        isFromAmazon,
        isFromGoogle,
        isFromMessenger,
        isFromWhatsapp,
        history,
    } = props;
    // const [showSuspiciousModal, setShowSuspiciousModal] = useState();

    const [handleBackPage, setHandleBackPage] = useState(undefined);
    const [titleStep1, setTitleStep1] = useState(undefined);

    useEffect(() => {
        if (hasActiveSession) {
            dispatch(sessionActions.logout()); // End any previous session open session
        }
        dispatch(sessionActions.loadFPData());
        // dispatch(loginActions.validateBiometricMigration());
        if (history.location.state !== null && history.location.state !== undefined) {
            setHandleBackPage(history.location.state.handleBackPage);
            setTitleStep1(history.location.state.titleStep1);
        }
    }, []);

    const setIsSubmiting = (newValue) => {
        dispatch(loginActions.setSubmiting(newValue));
    };

    // const imageSrc = !isDesktop ? imageMomentDay[`${momentDay}Mobile`] : imageMomentDay[momentDay];

    return (
        <>
            {isDesktop ? (
                <>
                   <Notification scopeToShow="recoveryUser" />
                    <MainContainer shouldHideOnLoad className="pt-0 max-width-full">
                        {(isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && <AssistantAlert />}
                        <Box display="flex" position="relative" zIndex="9" className="login-background">
                            <LoginWrapper transition="transition-drill-in">
                                <Step1Content /* setIsSubmiting={setIsSubmiting} */ isDesktop />
                            </LoginWrapper>
                        </Box>
                    </MainContainer>
                </>
            ) : (
                <>
                    <Notification scopeToShow="login" />
                    <Box display="flex" column alignX="between" className="scroll" fullHeight>
                        <Box
                            display="flex"
                            alignX="flex-end"
                            component="main"
                            position="relative"
                            zIndex="9"
                            className="login-background mt-auto">
                            {(isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && <AssistantAlert />}
                            <LoginWrapper>
                                <Step1Content setIsSubmiting={setIsSubmiting} isMobileNative={isMobileNative} />
                            </LoginWrapper>
                        </Box>
                    </Box>
                </>
            )}
            <PendingInvitationModal />
        </>
    );
};

const mapStateToProps = (state) => ({
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    activeRegion: loginSelectors.getRegion(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    isSubmiting: loginSelectors.getSubmiting(state),
});

LoginStep1.propTypes = {
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    isMobileNative: bool.isRequired,
    dispatch: func.isRequired,
    isFromAmazon: bool,
    isFromGoogle: bool,
    isFromMessenger: bool,
    isFromWhatsapp: bool,
    activeRegion: string,
    hasActiveSession: bool.isRequired,
    isSubmiting: bool.isRequired,
    location: shape({}).isRequired,
    isUserBloqued: bool,
};

LoginStep1.defaultProps = {
    isFromAmazon: false,
    isFromGoogle: false,
    isFromMessenger: false,
    isFromWhatsapp: false,
    activeRegion: "",
    isUserBloqued: false,
};

export default connect(mapStateToProps)(LoginStep1);
