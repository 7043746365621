import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import {
    actions as creditCardMovementsActions,
    selectors as creditCardMovementsSelectors,
} from "reducers/creditCardMovementDetails";
import { insertLinebreaks } from "util/string";
// import * as config from "util/config";

const PAGE_IDENTIFIER = "creditCards.movementDetails";
class CreditrCardMovementDetails extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        movement: shape({
            idProduct: string,
            idStatement: number,
            note: string,
        }).isRequired,
        isDesktop: bool,
        match: shape().isRequired,
    };

    static defaultProps = {
        isDesktop: null,
    };

    state = {
        note: {
            value: "",
            isEditing: true,
        },
        saving: false,
    };

    componentDidMount() {
        const { movement } = this.props;
        this.setState({ note: { value: movement?.note || "", isEditing: !movement?.note } });
    }

    handleChange = (event) => {
        const { value } = event.target;
        this.setState({ note: { value, isEditing: true } });
    };

    handleClick = () => {
        const { note } = this.state;

        const newValue = note?.value?.replace(/\n+/g, "\n");

        const { dispatch, movement } = this.props;

        this.setState({
            saving: true,
        });

        dispatch(
            creditCardMovementsActions.updateNoteRequest(
                movement.idProduct,
                movement.idStatement,
                insertLinebreaks(newValue),
                this.onFinish,
            ),
        );
    };

    onFinish = () => {
        const {
            note: { value },
        } = this.state;
        this.setState({ saving: false, note: { isEditing: false, value } });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardMovementsActions.closeOptions());
        dispatch(goBack());
    };

    render() {
        const {
            note: { value },
            saving,
        } = this.state;

        const { movement, isDesktop } = this.props;

        // const minAmountToIP = config.get("installment.purchase.min.movement.detail");

        // const validToIP = -1 * movement?.sourceAmount >= minAmountToIP && movement?.type === "Debit" && (movement?.financialCode && "" !== movement?.financialCode);

        return (
            <Fragment>
                {/* validToIP && (
                    <Head
                        onBack={this.handleBack}
                        title="creditCards.movement.detail.title"
                        {...{
                            actionButton: () => {
                                dispatch(push("/formCustom/installmentPurchase"));
                            },
                            actionButtonLabel: "installment.purchase.movementDetail.header.label",
                            actionButtonBsStyle: "primary",
                        }}
                    />
                    ) */}
                <Notification scopeToShow="movementDetail" />

                <MainContainer>
                    {movement && (
                        <Box background="white" borderRadius="default" className="p-5 mt-5 mt-md-0">
                            <form className="above-the-fold pb-0 pb-md-8">
                                <Row>
                                    <Col xs={12} md={10} mdOffset={1}>
                                        <Row>
                                            <Col xs={12}>
                                                <Box
                                                    border="bottom-background-divider-1"
                                                    className="pb-5 pt-md-5 pb-md-7">
                                                    <Text
                                                        bold
                                                        size={isDesktop ? "5" : "3"}
                                                        color="heading"
                                                        labelKey="creditCards.movement.detail.date"
                                                    />
                                                    <Text size={isDesktop ? "5" : "3"} color="heading">
                                                        {": "}
                                                        <FormattedDate date={movement?.date} />
                                                    </Text>
                                                </Box>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Box component="ul" className="data-list pt-0 pt-md-7">
                                                    <Box component="li" className="mb-3">
                                                        <Text
                                                            bold
                                                            size={isDesktop ? "5" : "3"}
                                                            color="heading"
                                                            labelKey="creditCards.movement.detail.dateprocess"
                                                        />
                                                        <Text size={isDesktop ? "5" : "3"} color="heading">
                                                            {": "}
                                                            <FormattedDate date={movement?.date} />
                                                        </Text>
                                                    </Box>
                                                    <Box component="li" className="mb-3">
                                                        <Text
                                                            bold
                                                            size={isDesktop ? "5" : "3"}
                                                            color="heading"
                                                            labelKey="creditCards.movement.detail.description"
                                                        />
                                                        <Text size={isDesktop ? "5" : "3"} color="heading">
                                                            {": "}
                                                            {movement?.concept}
                                                        </Text>
                                                    </Box>
                                                    <Box display="flex" component="li">
                                                        <Text
                                                            bold
                                                            size={isDesktop ? "5" : "3"}
                                                            color="heading"
                                                            labelKey="creditCards.movement.detail.amount"
                                                        />
                                                        <FormattedAmount
                                                            color={movement?.sourceAmount < 0 ? "error" : "heading"}
                                                            size={isDesktop ? "5" : "3"}
                                                            currency={`: ${movement?.sourceAmountCurrency}`}
                                                            quantity={movement?.sourceAmount}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Col>

                                            <Col xs={12} md={6} className="full-height">
                                                <Box display="flex" column fullHeight className="form-group">
                                                    <label htmlFor="movement.detail" className="control-label">
                                                        <Text
                                                            bold
                                                            size={isDesktop ? "5" : "3"}
                                                            color="heading"
                                                            labelKey="creditCards.movement.detail.notes"
                                                        />

                                                        <Box fullHeight className="input-group">
                                                            <textarea
                                                                id="movement.detail"
                                                                className="form-control full-height"
                                                                value={insertLinebreaks(value)}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Box>
                                                    </label>
                                                </Box>
                                            </Col>
                                            <Col xs={12} md={6} mdOffset={6}>
                                                <Row gapY="3">
                                                    <Col xs={12} md={6}>
                                                        <Button
                                                            bsStyle="outline"
                                                            label="global.cancel"
                                                            onClick={this.handleBack}
                                                            block
                                                        />
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        md={6}
                                                        className={classNames({
                                                            "grid-reversed": !isDesktop,
                                                        })}>
                                                        <Button
                                                            bsStyle="primary"
                                                            label="creditCards.movement.detail.saveNotes"
                                                            onClick={this.handleClick}
                                                            loading={saving}
                                                            block
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </Box>
                    )}
                </MainContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    movement: creditCardMovementsSelectors.getSelectedMovement(state),
});

export default connect(mapStateToProps)(resizableRoute(CreditrCardMovementDetails));
