export const NO_TRANSITION = "noTransition";

export const ACTIVITIES_WITHOUT_PREVIEW_STEP = [
    "administration.medium.modify.signature.send",
    "administration.medium.modify.contacts.send",
    "administration.restrictions.user.delete.send",
];

export const ACTIVITIES_WITH_SECOND_FACTOR_DATA = [
    "/token.entrust.activateUserToken",
    "/session.get",
];

export const MAX_FAILED_TIMES = 2;

export const TIME_IN_MILLIS_TO_REFRESH = 0;

export const TIME_CHANGE_OTP = 30;

export const MAX_LAST_LOGIN_TO_SHOW = 5;

export const CORPORATE_GROUP_ENVIRONMENT_TYPE = "corporateGroup";
export const RETAIL_ENVIRONMENT_TYPE = "retail";
export const CORPORATE_ENVIRONMENT_TYPE = "corporate";

export const SALARY_PAYMENT_ID_FORM = "salaryPayment";

export const REGION_USA = "US";
export const REGION_REST_OF_LATAM = "LATAM";
export const REGION_ARG = "AR";
export const REGION_CHI = "CL";
export const REGION_CAN = "CA";
export const REGION_UY = "UY";
export const REGION_COL = "CO";
export const REGION_MEX = "MX";
export const REGION_PER = "PE";
export const REGION_ECU = "EC";
export const REGION_PAR = "PY";
export const REGION_PAN = "PA";
export const REGION_CR = "CR";
export const REGION_VE = "VE";
export const AVAILABLE_REGIONS = [REGION_PAN];
export const ITBMS = "ITBMS";

export const COMPANY_NAME = "TekBNK";
export const GENERAL_CONDITIONS_CATEGORY_DEFAULT = "termsAndConditions";
export const ID_ACTIVITY_DOWNLOAD_STATE_PRE = "product.state.download.pre";
export const ID_ACTIVITY_DOWNLOAD_STATE = "product.state.download.send";
export const ID_ACTIVITY_EXCHANGE_POINTS = "product.exchange.points.send";

export const USD_CURRENCY = "USD";

export const STATUS_CHECK = {
    AVAILABLE: "AVAILABLE",
    CLEARED: "CLEARED",
    ISSUED: "ISSUED",
    STOPPED: "STOPPED",
    RETURNED: "RETURNED",
};

export const SPECIAL_CHARACTERS_REGEX = /^[a-zA-Z0-9 ]+$/i;

export const PRODUCT_TYPE = {
    ACCOUNT_CA: "CA",
    ACCOUNT_CC: "CC",
    CREDIT_CARD: "TC",
    DEPOSIT: "PF",
    LOAN: "PA",
};

export const TYPE_FILTER = {
    DATE: "DATE",
    AMOUNT: "AMOUNT",
    NUMBER: "NUMBER",
};

export const PERMISSION_ACCOUNT_STATEMENT = "accountStatement";
export const PERMISSION_CREDIT_CARD_STATEMENT = "creditCardStatement";
export const INSERT_SECURITY_QUESTION = "insertSecurityQuestions";
export const UPDATE_SECURITY_QUESTION = "updateSecurityQuestions";

export const TYPE_FILTER_USERS = {
    STATUS: "STATUS",
    DOCUMENT: "DOCUMENT",
    SIGNATURE: "SIGNATURE",
    NAME: "NAME",
};
