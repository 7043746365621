import Box from "pages/_components/Box";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { Link } from "react-router-dom";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";

const SimpleLayout = (props) => {
    const {
        component: ReceivedComponent,
        hasActiveSession,
        timesConfigFailed,
        timesI18nFailed,
        background,
        headerMobile,
        isDesktop,
        location,
        ...rest
    } = props;
    return (
            <Route {...rest}>
                <ErrorBoundary>
                    <Box className="app simple-layout" background={background}>
                        {(headerMobile || isDesktop) && (
                            <header className="app-header fixed-header background-component-background">
                                <Navbar collapseOnSelect fluid>
                                    <Navbar.Header>
                                        <div className="navbar-header-wrapper">
                                            <div className="d-flex items-center">
                                                <Navbar.Brand>
                                                    <Link className="navbar-brand" to="/desktop">
                                                        <Image src="images/logo-bolivariano.svg" />
                                                        <Text
                                                            className="visually-hidden"
                                                            labelKey="global.companyName"
                                                        />
                                                    </Link>
                                                </Navbar.Brand>
                                            </div>
                                        </div>
                                    </Navbar.Header>
                                </Navbar>
                            </header>
                        )}
                        <Box component="main" fullHeight>
                            <ReceivedComponent {...rest} />
                        </Box>
                    </Box>
                </ErrorBoundary>
            </Route>
    );
};

SimpleLayout.propTypes = {
    isDesktop: bool.isRequired,
    component: func.isRequired,
    hasActiveSession: bool,
    timesConfigFailed: number,
    timesI18nFailed: number,
    background: string,
    headerMobile: bool,
    location: shape({}),
};

SimpleLayout.defaultProps = {
    hasActiveSession: false,
    timesConfigFailed: 0,
    timesI18nFailed: 0,
    background: null,
    headerMobile: false,
    location: {},
};

export default resizableRoute(withRouter(SimpleLayout));
