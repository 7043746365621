import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as depositsActions, selectors as depositsSelectors } from "reducers/deposits";
import { actions as productsActions } from "reducers/products";
import { compose } from "redux";
import * as Yup from "yup";

const FORM_ID = "deposits.alias";
const PAGE_IDENTIFIER = "deposits.setAlias";
class DepositSetAlias extends Component {
    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(depositsActions.readDeposit(rest.match.params.id));
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { fetching, isSubmitting } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="deposits/setAlias" />
                <Head onBack={this.handleBack} title="deposits.alias.setAlias.title" />
                <MainContainer showLoader={fetching}>
                    <Form className="above-the-fold full-height">
                        <Box fullHeight fullWidth display="flex" column>
                            <Box background="white" borderRadius="default" className="mt-5 p-5 pb-9 mx-n-5">
                                <Field
                                    autoFocus
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="setAlias"
                                    type="text"
                                    labelNoMarginTop
                                    maxLength="28"
                                />
                            </Box>
                            <Button
                                type="submit"
                                className="mt-auto mb-7"
                                bsStyle="primary"
                                label="deposits.alias.setAlias.save"
                                loading={isSubmitting}
                                block
                            />
                        </Box>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    deposit: depositsSelectors.getSelectedDeposit(state),
    fetching: depositsSelectors.getFetching(state),
});

DepositSetAlias.propTypes = {
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    isSubmitting: bool.isRequired,
    match: shape().isRequired,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            setAlias: props.deposit ? props.deposit.productAlias : "",
            productId: props.match.params.id,
        }),
        validationSchema: () =>
            Yup.object().shape({
                setAlias: Yup.string().nullable(),
            }),
        handleSubmit: ({ productId, setAlias }, formikBag) => {
            formikBag.props.dispatch(productsActions.changeProductAlias(setAlias, productId, true), formikBag);
        },
    }),
)(DepositSetAlias);
