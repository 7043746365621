import classNames from "classnames";
import Box from "pages/_components/Box";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import FooterDesktop from "pages/_components/FooterDesktop";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import { bool, func, node, number, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { connect } from "react-redux";
import { Link, Route, withRouter } from "react-router-dom";
import PageTransition from "react-router-page-transition";
import { goBack } from "react-router-redux";
import { actions as loginActions } from "reducers/login";

class EnrollmentLayout extends Component {
    state = { transition: "" };

    static propTypes = {
        location: shape({
            pathname: string,
        }).isRequired,
        dispatch: func.isRequired,
        component: oneOfType([node, func]).isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        isDesktop: bool.isRequired,
        showBack: bool,
        mustGobackWithClose: bool,
        camera: string,
        transition: string,
        step: number,
        completed: number,
    };

    static defaultProps = {
        showBack: false,
        mustGobackWithClose: false,
        camera: null,
        transition: "transition-drill-in",
        step: null,
        completed: null,
    };

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { step } = this.props;
        const { step: nextStep } = nextProps;

        let transition = "transition-change-feature";

        if (
            typeof step !== "undefined" &&
            typeof nextStep !== "undefined" &&
            parseInt(step, 10) <= parseInt(nextStep, 10)
        ) {
            transition = "transition-drill-in";
        }

        if (
            typeof step !== "undefined" &&
            typeof nextStep !== "undefined" &&
            parseInt(step, 10) > parseInt(nextStep, 10)
        ) {
            transition = "transition-drill-out";
        }

        this.setState({
            transition,
        });
    }

    handleClick = () => {
        const { dispatch, mustGobackWithClose } = this.props;
        if (mustGobackWithClose) {
            dispatch(goBack());
        } else {
            dispatch(loginActions.reset());
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    generateInfoMessage = () => {
        const { location } = this.props;

        return (
            <>
                {location.pathname === "/enrollment/validateUser" && (
                    <Box className="alert-enrrolment-user-exist p-5" display="flex">
                        <Box>
                            <Image
                                src="images/icons/sb-warning.svg"
                                ariaLabel="tokenStatus"
                                wrapperWidth="4"
                                wrapperHeight="4"
                            />
                        </Box>
                        <Text
                            size="7"
                            color="heading"
                            regular
                            className="pl-3"
                            labelKey="enrollment.step3.validate.password.info"
                        />
                    </Box>
                )}
            </>
        );
    };

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative, camera, showBack } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, camera };
        const { transition } = this.state;
        const messageAlert = this.generateInfoMessage();

        return (
            <div className=" login-background">
                <Box display="flex" column position="relative" fullHeight fullWidth>
                    <PageTransition timeout={transition ? 600 : 0}>
                        <div className={classNames("app transition-item full-height")}>
                            <Notification scopeToShow="enrollment" />
                            <ErrorBoundary>
                                <ReceivedComponent {...matchProps} {...extras} />
                            </ErrorBoundary>
                        </div>
                    </PageTransition>
                </Box>
            </div>
        );
    };

    getClassPlatform = () => {
        const {
            isMobileNative,
        } = this.props;
        if (isMobileNative &&  window.device.platform === "iOS" && window?.StatusBar?.isVisible) {
            return "ios-safe-area";
        }
        return "";
    }

    getDesktopLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        // const messageAlert = this.generateInfoMessage();

        const { transition } = this.state;
        return (
            // <MomentDay className={`${transition} login-background`} isActiveCorporate={isActiveCorporate}>
            <div className={`${transition} login-background`}>
                <Box className="login-background-wrapper" fullWidth fullHeight>
                    <PageTransition
                        timeout={transition ? 600 : 0}
                        style={{
                            background: "linear-gradient(transparent, transparent 67.5%, black)",
                        }}>
                        <div className="app transition-item">
                            <ErrorBoundary>
                                <header className="app-header fixed-header">
                                    <Navbar collapseOnSelect fluid>
                                        <Navbar.Header>
                                            <div className="navbar-header-wrapper">
                                                <div className="d-flex items-center">
                                                    <Navbar.Brand>
                                                        <Link className={`navbar-brand logo-container ${this.getClassPlatform()}`}  to="/desktop">
                                                            {isMobile ? (
                                                                <Image src="images/logo-bolivariano-mobile.svg" />
                                                            ) : (
                                                                <Image src="images/logo-bolivariano.svg" />
                                                            )}
                                                            <Text
                                                                className="visually-hidden"
                                                                labelKey="global.companyName"
                                                            />
                                                        </Link>
                                                    </Navbar.Brand>
                                                </div>
                                            </div>
                                        </Navbar.Header>
                                    </Navbar>
                                </header>
                                <section className="container--layout">
                                    <Box className="login-desktop-wrapper panel enrollment-notification">
                                        <main
                                            className={isMobile ? "app-default-main full-height" : "app-default-main"}
                                            role="main">
                                            <Notification scopeToShow="enrollment" />
                                            <MainContainer shouldHideOnLoad className="pt-0 max-width-full">
                                                <Box
                                                    display="flex"
                                                    position="relative"
                                                    zIndex="9"
                                                    className="login-background">
                                                    <LoginWrapper transition="transition-drill-in">
                                                        <ReceivedComponent {...matchProps} {...extras} />
                                                    </LoginWrapper>
                                                </Box>
                                            </MainContainer>
                                        </main>
                                        <FooterDesktop isLogin />
                                    </Box>
                                </section>
                            </ErrorBoundary>
                        </div>
                    </PageTransition>
                </Box>
            </div>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
                <Route
                    {...rest}
                    render={(matchProps) => {
                        const { isMobile } = this.props;

                        if (isMobile) {
                            return <>{this.getMobileLayout(matchProps)}</>;
                        }

                        return <>{this.getDesktopLayout(matchProps)}</>;
                    }}
                />
        );
    }
}

export default withRouter(connect()(resizableRoute(EnrollmentLayout)));
