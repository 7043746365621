import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import PinInput from "pages/_components/fields/PinInput";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import {
    actions as changeStatusProductActions,
    selectors as changeStatusProductSelectors,
} from "reducers/changeStatusProduct";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import CredentialTokenComponent from "../credential/CredentialTokenComponent";

export const actionChangeStatusProduct = {
    Block: "block",
    Unlock: "unlock",
    Active: "active",
};

const ConfirmationModal = (props) => {
    const {
        credentials,
        dispatch,
        showModal,
        dataModal,
        hasCompleteCredentialGroups,
        isDesktop,
    } = props;
    const {
        dataModal: {
            productData,
            actionName,
            productType,
            idActivity: idActivityData,
            statusExpected,
            actionImage,
            closeButton = false,
            paramsNotification,
            productionStatusExpected,
            isPinAssigment,
            email,
        },
    } = props;

    const idActivity = idActivityData || `${productType}.changeStatus.${actionName}.send`;

    useEffect(() => {
        if (showModal) {
            dispatch(changeStatusProductActions.credentialsGroupsRequest({ idActivity }));
        }
    }, [dataModal]);

    const handleSubmit = (credentialsForm, formikBag) => {
        const {
            dataModal: { submitAction: submitActionData },
        } = props;
        const submitAction = submitActionData || changeStatusProductActions.changeProductStatusRequest;
        dispatch(
            submitAction({
                ...productData,
                productType,
                statusExpected,
                credentialsForm,
                formikBag,
                idActivity,
                actionName,
                paramsNotification,
                productionStatusExpected,
            }),
        );
    };

    const validationAccessTokenScheme = () =>
        credentials?.reduce(
            (values, credential) => ({
                ...values,
                [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
            }),
            {},
        ) || {};

    const genericInitialValuesForm = () =>
        credentials?.reduce(
            (values, credential) => ({
                ...values,
                [credential]: "",
            }),
            {},
        ) || {};

    const getFormValidationSchema = () => {
        if (!productType) {
            return Yup.object().shape({
                ...validationAccessTokenScheme(),
            });
        }

        if (productType === "creditCards" && isPinAssigment) {
            return Yup.object().shape({
                ...validationAccessTokenScheme(),
                pinCode: Yup.string()
                    .required(i18n.get(`form.credential.pin.required`))
                    .min(4, i18n.get(`form.credential.pin.size.required`))
                    .max(4, i18n.get(`form.credential.pin.size.required`)),
            });
        }

        return Yup.object().shape({
            ...validationAccessTokenScheme(),
        });
    };

    const getFormInitialValues = () => {
        if (!productType) {
            return genericInitialValuesForm();
        }

        if (productType === "creditCards" && isPinAssigment) {
            return { ...genericInitialValuesForm(), pinCode: "" };
        }

        return genericInitialValuesForm();
    };

    const handleHideModal = () => {
        dispatch(changeStatusProductActions.modalHide());
    };

    const getRenderCreditCardBodyModal = () => (
        <Box display="flex" column alignY="between" fullHeight>
            <Text
                className="mb-auto"
                key="text"
                align="center"
                color="heading"
                labelKey={`creditCards.drawer.${actionName}.text`}
                CARD_NUMBER={productData?.numberMask || ""}
                TYPE={
                    productData?.parametersSubmit?.type.charAt(0).toUpperCase() +
                        productData?.parametersSubmit?.type.toLowerCase().slice(1) || ""
                }
            />
            <Box display="flex" column className="mb-auto mt-7">
                <Text
                    className="mb-9"
                    key="disclaimer1"
                    align="center"
                    color="primary"
                    bold
                    labelKey={`creditCards.drawer.${actionName}.disclaimer1`}
                />
                {actionName === actionChangeStatusProduct.Block && (
                    <Text
                        key="disclaimer2"
                        size="6"
                        align="center"
                        color="heading"
                        labelKey={`creditCards.drawer.${actionName}.disclaimer2`}
                        className="mb-3"
                    />
                )}
            </Box>
        </Box>
    );
    const getRenderDebitCardBodyModal = () => (
        <Box display="flex" column alignY="between" fullHeight>
            <Text
                className="mb-auto"
                key="text"
                align="center"
                color="heading"
                labelKey={`debitCards.drawer.${actionName}.text`}
                CARD_NUMBER={productData.shortLabel}
            />
            <Box display="flex" column className="mb-auto">
                <Text
                    className="mb-9"
                    key="disclaimer1"
                    align="center"
                    color="primary"
                    size={isDesktop ? "5" : "4"}
                    bold
                    labelKey={`creditCards.drawer.${actionName}.disclaimer1`}
                />
            </Box>
        </Box>
    );

    const getRenderAccountsBodyModal = () => (
        <Box display="flex" column alignY="between" fullHeight>
            <Text
                key="text"
                align="center"
                color="heading"
                labelKey={`accounts.drawer.${actionName}.text`}
                ACCOUNT_NUMBER={productData?.accountNumber}
                TYPE={productData?.shortLabel || ""}
            />
            <Box display="flex" column alignY="center" className="my-auto">
                <Text
                    className="mb-6"
                    key="disclaimer1"
                    align="center"
                    color="primary"
                    size={isDesktop ? "5" : "4"}
                    bold
                    labelKey={`accounts.drawer.${actionName}.disclaimer2`}
                />
                <Text key="disclaimer1" align="center" labelKey={`accounts.drawer.${actionName}.disclaimer1`} />
            </Box>
        </Box>
    );

    const getRenderCreditCardPinAssignmentBodyModal = () => (
        <Box display="flex" column alignX="center" className="mb-auto">
            <Text
                key="text"
                align="center"
                color="heading"
                labelKey="creditCards.drawer.pinAssignment.text"
                CARD_DESC={`${productData?.franchise?.charAt(0).toUpperCase()}${productData?.franchise
                    .slice(1)
                    .toLowerCase()} ${productData?.numberMask || ""}`}
            />
            <Box className="my-9">
                <Field
                    name="pinCode"
                    component={PinInput}
                    placeholder="*"
                    maxLength={1}
                    labelNoMarginTop
                    showLabel={false}
                    type="number"
                    isMasked
                    inputMode="numeric"
                />
            </Box>
        </Box>
    );

    const getRenderDebitCardPinAssignmentBodyModal = () => (
        <Box display="flex" column alignX="center" className="mb-11">
            <Text
                key="text"
                align="center"
                color="heading"
                className="mb-11"
                labelKey="debitCards.drawer.requestPin.disclaimer1"
                CARD_TYPE={productData.cardBrand}
                CARD_NUMBER={`${productData?.franchise?.charAt(0).toUpperCase()}${productData?.franchise
                    ?.slice(1)
                    .toLowerCase()} ${productData?.numberMask || ""}`}
            />
            <Text
                key="disclaimer2"
                color="heading"
                align="center"
                className="my-5"
                labelKey="debitCards.drawer.requestPin.disclaimer2"
            />
            <Text key="email" align="center" color="heading" size="3" className="mb-7" bold>
                {email}
            </Text>
            <Text
                key="disclaimer3"
                align="center"
                color="heading"
                size="6"
                labelKey="debitCards.drawer.requestPin.disclaimer3"
            />
            <Button
                removeMinHeight
                bsStyle="link"
                label="debitCards.drawer.requestPin.clickHere"
                onClick={() => {
                    dispatch(routerActions.push("/settings/changeEmail"));
                    dispatch(changeStatusProductActions.modalHide());
                }}
            />
        </Box>
    );

    const renderBody = () => {
        if (productType === "creditCards") {
            return isPinAssigment ? getRenderCreditCardPinAssignmentBodyModal() : getRenderCreditCardBodyModal();
        }
        if (productType === "debitCards") {
            return isPinAssigment ? getRenderDebitCardPinAssignmentBodyModal() : getRenderDebitCardBodyModal();
        }
        if (productType === "accounts") {
            return getRenderAccountsBodyModal();
        }
        return null;
    };

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={handleHideModal}
            show={showModal}
            className={classNames({ drawer: true })}>
            <div className="modal-container">
                <Modal.Header closeButton={closeButton}>
                    <Modal.Title id="modalTitleID">
                        <Box display="flex" alignX="center" alignY="center">
                            <Text labelKey={dataModal?.title} />
                            {actionImage && (
                                <Box display="flex">
                                    <Image key="src" src={actionImage} className="ml-3" width="8" height="8" />
                                </Box>
                            )}
                        </Box>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box
                        display="flex"
                        column
                        alignY="between"
                        className="scrollable-content px-0 px-md-9"
                        fullWidth
                        fullHeight>
                        {hasCompleteCredentialGroups && hasCompleteCredentialGroups === true > 0 ? (
                            <Formik
                                validateOnBlur={false}
                                validateOnChange={false}
                                initialValues={getFormInitialValues()}
                                validationSchema={getFormValidationSchema()}
                                onSubmit={handleSubmit}>
                                {(propsForm) => (
                                    <Form className="display-flex flex-direction-column full-height">
                                        {renderBody()}

                                        {credentials && (
                                            <Box
                                                display="flex"
                                                column
                                                alignX="center"
                                                className="form-confirmation-box mt-auto mb-9">
                                                <CredentialTokenComponent
                                                    credentials={credentials}
                                                    propsForm={propsForm}
                                                    onChangeToken={(token) => {
                                                        if (props?.setFieldValue) {
                                                            props.setFieldValue("otp", token);
                                                        } else if (propsForm?.setFieldValue) {
                                                            propsForm.setFieldValue("otp", token);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        <Box className="mt-7 pb-9 pb-md-12">
                                            <Button
                                                block
                                                type="submit"
                                                label="global.confirm"
                                                bsStyle="primary"
                                                loading={propsForm?.isSubmitting || false}
                                                disabled={propsForm?.isSubmitting || false}
                                            />
                                            <Button
                                                block
                                                type="button"
                                                bsStyle="outline"
                                                onClick={handleHideModal}
                                                disabled={propsForm?.isSubmitting || false}
                                                label="global.cancel"
                                            />
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <PageLoading loading />
                        )}
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    credentials: arrayOf(string).isRequired,
    dataModal: shape({}).isRequired,
    dispatch: func.isRequired,
    hasCompleteCredentialGroups: bool,
    isDesktop: bool.isRequired,
    setFieldValue: func,
    showModal: bool.isRequired,
    submitAction: func,
};

ConfirmationModal.defaultProps = {
    hasCompleteCredentialGroups: false,
    setFieldValue: undefined,
    submitAction: undefined,
};

const mapStateToProps = (state) => ({
    showModal: changeStatusProductSelectors.getDisplayModal(state),
    dataModal: changeStatusProductSelectors.getDataModal(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(changeStatusProductSelectors.getCredentialsGroups(state)),
    hasCompleteCredentialGroups: changeStatusProductSelectors.hasCompleteCredentialGroups(state),
});

export default connect(mapStateToProps)(resizableRoute(ConfirmationModal));
