import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import globalTypes from "reducers/types/global";

export const types = {
    CHANGE_PRODUCT_ALIAS: "products/CHANGE_PRODUCT_ALIAS",
    CHANGE_PRODUCT_ALIAS_SUCCESS: "products/CHANGE_PRODUCT_ALIAS_SUCCESS",

    TOGGLE_FAVORITE_REQUEST: "products/TOGGLE_FAVORITE_REQUEST",
    TOGGLE_FAVORITE_SUCCESS: "products/TOGGLE_FAVORITE_SUCCESS",
    TOGGLE_FAVORITE_FAILURE: "products/TOGGLE_FAVORITE_FAILURE",
    STATE_DOWNLOAD_PRE_REQUEST: "products/STATE_DOWNLOAD_PRE_REQUEST",
    STATE_DOWNLOAD_PRE_SUCCESS: "products/STATE_DOWNLOAD_PRE_SUCCESS",
    STATE_DOWNLOAD_PRE_FAILURE: "products/STATE_DOWNLOAD_PRE_FAILURE",
    STATE_DOWNLOAD_REQUEST: "products/STATE_DOWNLOAD_REQUEST",
    STATE_DOWNLOAD_SUCCESS: "products/STATE_DOWNLOAD_SUCCESS",
    STATE_DOWNLOAD_CLEAR: "products/STATE_DOWNLOAD_CLEAR",
    SET_SELECT_PRODUCT_INFO: "creditCardMovements/SET_SELECT_PRODUCT_INFO",
    SYNC_ENVIROMENT_PRODUCT_REQUEST: "products/SYNC_ENVIROMENT_PRODUCT_REQUEST",
    SYNC_ENVIROMENT_PRODUCT_SUCCESS: "products/SYNC_ENVIROMENT_PRODUCT_SUCCESS",
    SYNC_ENVIROMENT_PRODUCT_FAILURE: "products/SYNC_ENVIROMENT_PRODUCT_FAILURE",

    EXCHANGE_POINTS_REQUEST: "products/EXCHANGE_POINTS_REQUEST",
    EXCHANGE_POINTS_SUCCESS: "products/EXCHANGE_POINTS_SUCCESS",

    SHOW_EXCHANGE_POINTS_FORM: "SHOW_EXCHANGE_POINTS_FORM",
    HIDE_EXCHANGE_POINTS_FORM: "HIDE_EXCHANGE_POINTS_FORM",

    RESET_CHANGED_POINTS: "RESET_CHANGED_POINTS",
    OPEN_ADULTHOOD_MODAL: "OPEN_ADULTHOOD_MODAL",
};

export const INITIAL_STATE = {
    metadataDownloadStateProduct: undefined,
    stateProductList: [],
    fetchingProduct: false,
    showEmptyList: false,
    selectProduct: undefined,
    fetchingSynchronization: false,
    showExchangePointForm: false,
    changedPoints: undefined,
    showAdulthoodModal: false,
    synchronizationExecuted: false,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case globalTypes.CLEAN_UP:
            return { ...INITIAL_STATE };
        case types.STATE_DOWNLOAD_PRE_REQUEST:
            return { ...state, fetchingProduct: true, stateProductList: [], showEmptyList: false };
        case types.STATE_DOWNLOAD_PRE_SUCCESS:
            return { ...state, metadataDownloadStateProduct: action.payload, fetchingProduct: false };
        case types.STATE_DOWNLOAD_SUCCESS:
            return { ...state, stateProductList: action.payload };
        case types.STATE_DOWNLOAD_PRE_FAILURE:
            return { ...state, fetchingProduct: false };
        case types.STATE_DOWNLOAD_REQUEST:
            return { ...state, showEmptyList: true };
        case types.SET_SELECT_PRODUCT_INFO:
            return { ...state, selectProduct: action?.product || undefined };
        case types.TOGGLE_FAVORITE_REQUEST:
            return { ...state, fetchingProduct: true };
        case types.TOGGLE_FAVORITE_SUCCESS:
        case types.TOGGLE_FAVORITE_FAILURE:
            return { ...state, fetchingProduct: false };
        case types.SYNC_ENVIROMENT_PRODUCT_REQUEST:
            return { ...state, fetchingSynchronization: true };
        case types.SYNC_ENVIROMENT_PRODUCT_SUCCESS:
            return {
                ...state,
                fetchingSynchronization: false,
                synchronizationExecuted: action?.synchronizationExecuted,
            };
        case types.SYNC_ENVIROMENT_PRODUCT_FAILURE:
            return { ...state, fetchingSynchronization: false };
        case types.SHOW_EXCHANGE_POINTS_FORM:
            return { ...state, showExchangePointForm: true };
        case types.HIDE_EXCHANGE_POINTS_FORM:
            return { ...state, showExchangePointForm: false };

        case types.EXCHANGE_POINTS_REQUEST:
            return { ...state };
        case types.EXCHANGE_POINTS_SUCCESS:
            return { ...state, changedPoints: action.changedPoints };
        case types.RESET_CHANGED_POINTS:
            return { ...state, changedPoints: undefined };
        case types.OPEN_ADULTHOOD_MODAL:
            return { ...state, showAdulthoodModal: action.show };
        default:
            return state;
    }
};

export default persistReducer(
    {
        storage,
        key: "products",
        blacklist: ["fetchingSynchronization"],
    },
    reducer,
);

export const actions = {
    changeProductAlias: (alias, idProduct, backAfterCall = false, formikBag = null) => ({
        type: types.CHANGE_PRODUCT_ALIAS,
        alias,
        idProduct,
        backAfterCall,
        formikBag,
    }),
    toggleFavorite: (idProduct, productType, favorite, onFinish, fromList) => ({
        type: types.TOGGLE_FAVORITE_REQUEST,
        idProduct,
        productType,
        favorite,
        onFinish,
        fromList,
    }),
    metadataDownloadStateProduct: () => ({ type: types.STATE_DOWNLOAD_PRE_REQUEST }),
    downloadStateProduct: (month, year, productId, formikBag) => ({
        type: types.STATE_DOWNLOAD_REQUEST,
        month,
        year,
        productId,
        formikBag,
    }),
    setSelectProduct: (product) => ({
        type: types.SET_SELECT_PRODUCT_INFO,
        product,
    }),
    syncEnviromentProduct: (shouldGetLastSynchronization, onFinish, showSnackbar) => ({
        type: types.SYNC_ENVIROMENT_PRODUCT_REQUEST,
        shouldGetLastSynchronization,
        onFinish,
        showSnackbar,
    }),
    exchangePointsProduct: (productId, formikBag, points, totalPoints, moneyValue) => ({
        type: types.EXCHANGE_POINTS_REQUEST,
        productId,
        formikBag,
        points,
        totalPoints,
        moneyValue,
    }),
    showExchangePointForm: () => ({
        type: types.SHOW_EXCHANGE_POINTS_FORM,
    }),
    hideExchangePointForm: () => ({
        type: types.HIDE_EXCHANGE_POINTS_FORM,
    }),
    resetChangedPoints: () => ({
        type: types.RESET_CHANGED_POINTS,
    }),
    openCloseAdulthoodModal: (show) => ({
        type: types.OPEN_ADULTHOOD_MODAL,
        show,
    }),
};
export const selectors = {
    getMetadataDownloadStateProduct: ({ products }) => products.metadataDownloadStateProduct,
    getStateProductList: ({ products }) => products.stateProductList,
    isFeatchingProduct: ({ products }) => products.fetchingProduct,
    showEmptyList: ({ products }) => products.showEmptyList,
    getSelectProduct: ({ products }) => products.selectProduct,
    getCompleteFavorites: ({ products }) => products.fetchingProduct,
    isfetchingSynchronization: ({ products }) => products.fetchingSynchronization,
    getShowExchangePointForm: ({ products }) => products.showExchangePointForm,
    getChangedPoints: ({ products }) => products.changedPoints,
    getShowAdulthoodModal: ({ products }) => products.showAdulthoodModal,
    getSynchronizationExecuted: ({ products }) => products.synchronizationExecuted,
};
