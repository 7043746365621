import React, { useState } from "react";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { bool, node, string } from "prop-types";
import ImageSlider from "pages/_components/ImageSlider";
import HelpModal from "pages/_components/modal/HelpModal";
import Text from "pages/_components/Text";
import PageTransition from "react-router-page-transition";
import classNames from "classnames";

const LoginFormContainer = ({ children }) => (
    <Box display="flex" fullWidth fullHeight className="login-form" alignY="center">
        <Box display="flex" column fullWidth alignX="center">
            {children}
        </Box>
    </Box>
);

LoginFormContainer.propTypes = {
    children: node.isRequired,
};

const LoginWrapper = (props) => {
    const { isMobile, transition, children, showInfoLinks, showHelpLinks } = props;
    const [showHelpCenterModal, setShowHelpCenterModal] = useState(false);
    const [showHelpCybersecurityModal, setShowHelpCybersecurityModal] = useState(false);

    if (isMobile) {
        return children;
    }

    const closeModals = () => {
        setShowHelpCenterModal(false);
        setShowHelpCybersecurityModal(false);
    };

    return (
        <Box className="login-wrapper l-wrapper" fullWidth>
            <Box className={classNames("login-wrapper-form l-wrapper-f", transition || "")}>
                {!transition ? (
                    <LoginFormContainer>{children}</LoginFormContainer>
                ) : (
                    <PageTransition timeout={600}>
                        <div className="transition-item full-height full-width">
                            <LoginFormContainer>{children}</LoginFormContainer>
                        </div>
                    </PageTransition>
                )}
            </Box>
            {showInfoLinks && (
                <Box className="login-wrapper-info" borderRadius="right-lg">
                    <ImageSlider
                        className="no-mobile"
                        images={["images/login_slider_1.png", "images/login_slider_2.png", "images/login_slider_3.png"]}
                    />
                    {showHelpLinks && (
                        <div className="login-wrapper-info-links">
                            <Button
                                onClick={() => setShowHelpCenterModal(true)}
                                bsStyle="link"
                                defaultLabelText={i18n.get("", "Portal de ayuda")}
                                primaryLink
                                className="px-2"
                                black
                            />
                            <div className="login-wrapper-info-links-divider" />
                            <Button
                                onClick={() => setShowHelpCybersecurityModal(true)}
                                bsStyle="link"
                                defaultLabelText={i18n.get("general.login.form.security.label")}
                                primaryLink
                                className="px-2"
                                black
                            />
                        </div>
                    )}
                </Box>
            )}
            <HelpModal
                modalShow={showHelpCenterModal}
                cancelFunction={closeModals}
                headingText={i18n.get("", "Portal de ayuda")}
                headingIcon="images/iconTitles/security_questions.svg">
                <>
                    <Button
                        externalHref="https://portaldeayuda.bolivariano.com/si-tengo-una-consulta-como-me-comunico-con-el-banco"
                        defaultLabelText={i18n.get("", "¿Cómo ser cliente de banca empresas?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://portaldeayuda.bolivariano.com/es-cliente-y-es-la-primera-vez-que-ingresa"
                        defaultLabelText={i18n.get("", "¿Es cliente y es la primera vez que ingresa?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://portaldeayuda.bolivariano.com/donde-puedo-solicitar-productos-y-servicios"
                        defaultLabelText={i18n.get("", "¿Cómo puedo solicitar los productos y servicios SAT?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://reclamos.bolivariano.com/#/inicio?origen=cliente"
                        defaultLabelText={i18n.get("", "¿Dónde puedo ingresar un reclamo?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Text
                        defaultValue={i18n.get("", "Otras acciones")}
                        className="my-5"
                        color="text-secondary"
                        size="5"
                        align="self-center"
                    />
                    <Button
                        externalHref="https://portaldeayuda.bolivariano.com/el-c%C3%B3digo-de-invitaci%C3%B3n-que-me-enviaron-para-acceder-a-la-banca-digital-de-empresa-ha-caducado-c%C3%B3mo-solicito-otra-invitaci%C3%B3n"
                        defaultLabelText={i18n.get("", "Reenviar datos para primer inicio de sesión")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                </>
            </HelpModal>
            <HelpModal
                modalShow={showHelpCybersecurityModal}
                cancelFunction={closeModals}
                headingText={i18n.get("general.login.form.security.label")}
                headingIcon="images/iconTitles/security_device.svg">
                <>
                    <Button
                        externalHref="https://landing.bolivariano.com.ec/es/seguritipsbb"
                        defaultLabelText={i18n.get("", "Principales delitos de delincuencia financiera")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://blog.bolivariano.com.ec/seguritipsbb-blog/configura-tu-reconocimiento-f%C3%A1cil-o-reconocimiento-dactilar-en-tu-24m%C3%B3vil"
                        defaultLabelText={i18n.get("", "Configure su reconocimiento facial y dactilar")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://blog.bolivariano.com.ec/seguritipsbb-blog/conoce-mas-sobre-el-smishing"
                        defaultLabelText={i18n.get("", "Conozca más sobre el smishing")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://blog.bolivariano.com.ec/seguritipsbb-blog/consejos-de-seguridad-inform%C3%A1tica"
                        defaultLabelText={i18n.get("", "Consejos de ciberseguridad")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                </>
            </HelpModal>
        </Box>
    );
};

LoginWrapper.propTypes = {
    children: node.isRequired,
    isMobile: bool,
    transition: string,
    showInfoLinks: bool,
    showHelpLinks: bool,
};

LoginWrapper.defaultProps = {
    isMobile: false,
    transition: null,
    showInfoLinks: true,
    showHelpLinks: true,
};

export default LoginWrapper;
