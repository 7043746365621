import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import { Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Image from "../Image";

const MULTIPLE_BUTTON = "MULTIPLE_BUTTON";
const ONLY_ACCEPT_BUTTON = "ONLY_ACCEPT_BUTTON";
const ONLY_CANCEL_BUTTON = "ONLY_CANCEL_BUTTON";
const NONE_BUTTON = "NONE_BUTTON";

const DEFAULT_MODAL_ID = "TextAndButtonsModal";
const TextAndButtonsModal = (props) => {
    const {
        acceptFunction,
        cancelFunction,
        cancelLabel,
        confirmLabel,
        externalHref,
        headingText,
        headingSize,
        isDesktop,
        loading,
        modalShow,
        text,
        textClassName,
        continueLabel,
    } = props;

    const getTypeButtons = () => {
        if ((acceptFunction || externalHref) && cancelFunction) {
            return MULTIPLE_BUTTON;
        }

        if (acceptFunction) {
            return ONLY_ACCEPT_BUTTON;
        }

        if (cancelFunction) {
            return ONLY_CANCEL_BUTTON;
        }

        return NONE_BUTTON;
    };

    const renderButtonsDesktop = () => {
        const typeButton = getTypeButtons();
        if (typeButton === MULTIPLE_BUTTON) {
            return (
                <Box display="flex" column fullWidth>
                    <Row className="px-0">
                        <Col xs={6}>
                            <Button
                                label={cancelLabel || "global.cancel"}
                                bsStyle="outline"
                                onClick={cancelFunction}
                                block
                            />
                        </Col>
                        <Col xs={6}>
                            <Button
                                label={confirmLabel || "global.accept"}
                                bsStyle="primary"
                                onClick={acceptFunction}
                                externalHref={externalHref}
                                block
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Box>
            );
        }

        if (typeButton === ONLY_ACCEPT_BUTTON) {
            return (
                <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                        <Button
                            label={confirmLabel || "global.accept"}
                            bsStyle="primary"
                            onClick={acceptFunction}
                            externalHref={externalHref}
                            block
                        />
                    </Col>
                </Row>
            );
        }

        if (typeButton === ONLY_CANCEL_BUTTON) {
            return (
                <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                        <Button
                            label={cancelLabel || "global.cancel"}
                            bsStyle="outline"
                            onClick={cancelFunction}
                            block
                        />
                    </Col>
                </Row>
            );
        }

        return <div />;
    };

    const renderButtonsMobile = () => {
        const typeButton = getTypeButtons();
        if (typeButton === MULTIPLE_BUTTON) {
            return (
                <Box className="modal-btn-wrapper">
                    <Button
                        label={confirmLabel || "global.accept"}
                        bsStyle="primary"
                        externalHref={externalHref}
                        onClick={acceptFunction}
                        block
                        loading={loading}
                    />
                    <Button
                        label={continueLabel || "global.continue"}
                        bsStyle="outline"
                        onClick={cancelFunction}
                        block
                    />
                </Box>
            );
        }

        if (typeButton === ONLY_ACCEPT_BUTTON) {
            return (
                <Box className="modal-btn-wrapper">
                    <Button
                        label={confirmLabel || "global.accept"}
                        bsStyle="primary"
                        externalHref={externalHref}
                        onClick={acceptFunction}
                        block
                        loading={loading}
                    />
                </Box>
            );
        }

        if (typeButton === ONLY_CANCEL_BUTTON) {
            return (
                <Box className="modal-btn-wrapper">
                    <Button label={cancelLabel || "global.cancel"} bsStyle="outline" onClick={cancelFunction} block />;
                </Box>
            );
        }

        return <div />;
    };

    return (
        <div className="modal-container">
            <Notification scopeToShow="generalNotification" />
            <Modal show={modalShow}>
                <Box className="p-7">
                    <Box isplay="flex" alignX="center" className="my-9">
                        <Image src="images/icons/modals/warning.svg" />
                    </Box>
                    <Box display="flex" fullWidth alignX="center">
                        <Text size={headingSize} color="heading" align="center" bold>
                            {headingText}
                        </Text>
                    </Box>
                    <Box display="flex" alignX="center" className={classNames("mt-5 mb-9", textClassName)}>
                        <Text align="center" size="5" color="text" defaultValue={text} breakWord />
                    </Box>

                    {renderButtonsMobile()}
                </Box>
            </Modal>
        </div>
    );
};

TextAndButtonsModal.propTypes = {
    acceptFunction: func.isRequired,
    cancelFunction: func,
    cancelLabel: string,
    confirmLabel: string,
    externalHref: string,
    headingText: string.isRequired,
    headingSize: string,
    isDesktop: bool.isRequired,
    loading: bool,
    modalId: string,
    modalShow: bool.isRequired,
    text: string.isRequired,
    textClassName: string,
    continueLabel: string,
};
TextAndButtonsModal.defaultProps = {
    cancelLabel: "global.cancel",
    confirmLabel: "global.accept",
    headingSize: "3",
    externalHref: undefined,
    loading: false,
    modalId: DEFAULT_MODAL_ID,
    textClassName: "mx-md-12",
    cancelFunction: undefined,
    continueLabel: "global.continue",
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(resizableRoute(TextAndButtonsModal));
