/*
 * API middleware to interact with the RESTful API (new composite Microservices)
 */
import getAxiosObject from "./axiosUtils";
import { store } from "../store";


const apiAxios = getAxiosObject(window.API_URL_MS);

const cachedEtags = new Map();

apiAxios.interceptors.request.use((request) => {
    const { environment } = store.getState().session;
    if (cachedEtags.has(request.url)) {
        request.headers["If-None-Match"] = cachedEtags.get(request.url);
    }
    request.headers["idEnvironment"] = environment.id;
    return request;
});

apiAxios.interceptors.response.use(
    (response) => {
        if (response.headers.etag) {
            cachedEtags.set(response.request.responseURL, response.headers.etag);
        }

        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status < 500) {
                return error.response;
            }
        }

        // se agrega el atributo para saber que es un error de saga
        // eslint-disable-next-line
        error.httpError = true;

        throw error;
    },
);

export const setAuthToken = (token) => {
    apiAxios.defaults.headers.common.Authorization = `bearer ${token}`;
};

export const getWithAccessToken = (endpoint, params) => apiAxios.get(endpoint, { params });

export const postWithAccessToken = (endpoint, params) => apiAxios.post(endpoint, params);
