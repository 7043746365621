import classNames from "classnames";
import AccessBar from "pages/_components/AccessBar";
import Box from "pages/_components/Box";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import NavigationBarDesktop from "pages/_components/NavigationBarDesktop";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import ResizableComponent from "pages/_components/ResizableComponent";
import Row from "pages/_components/Row";
import Menu from "pages/_components/menu/Menu";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import { arrayOf, bool, func, number, oneOf, shape, string } from "prop-types";
import LoadingGlobalProvider from "providers/LoadingGlobalProvider";
import UpdateUserDataProvider from "providers/updateUserData/UpdateUserDataProvider";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import PageTransition from "react-router-page-transition";
import { routerActions } from "react-router-redux/actions";
import { actions as desktopActions, selectors as desktopSelectors } from "reducers/desktop";
import * as config from "util/config";
import WithPermissionsRedirect from "../../_components/WithPermissionsRedirect";

const DefaultStepperLayout = ({
    aditionalClassName,
    isDesktop,
    isMobile,
    isTablet,
    isMobileNative,
    component: ReceivedComponent,
    componentProps,
    transition,
    withoutLayout,
    somePermissions,
    permissions,
    comparator,
    scrollable,
    isSidebarCollapsed,
    noBar,
    dispatch,
    step,
    stepsList,
    completed,
    title,
    customBackURL,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [transitionState, setTransitionState] = useState(null);

    useEffect(() => {
        if (window.innerWidth > 1232) {
            dispatch(desktopActions.changeStatusSidebarCollapsed(false));
        }
    }, [dispatch]);

    const handlerResizeEventSidebar = () => {
        const isSizeTablet = window.innerWidth < 1232;
        dispatch(desktopActions.changeStatusSidebarCollapsed(isSizeTablet));
    };

    const handleBack = () => {
        if (customBackURL && customBackURL !== "") {
            dispatch(routerActions.replace(customBackURL));
        } else {
            dispatch(routerActions.goBack());
        }
    };

    const getMobileLayout = (matchProps) => {
        const extras = { isMobile, isDesktop, ...componentProps };

        const accessBarBtns = [
            {
                image: "images/icons/home.svg",
                label: "login.access.bar.home",
                href: "/desktop",
                className: "secondary",
            },
            {
                image: "images/icons/chatWithVane.svg",
                label: "login.access.bar.chat.vane",
                externalHref: config.get("accessBar.chat.url"),
                className: "secondary",
            },
            {
                image: "images/icons/requests.svg",
                label: "desktop.widgets.short.cuts.requests",
                externalHref: config.get("accessBar.requests.url"),
                className: "secondary",
            },
            {
                image: "images/icons/configuration.svg",
                label: "login.access.bar.configuration",
                href: "/settings",
                className: "secondary",
            },
        ];
        const wally = {
            image: "images/wally.svg",
            label: "wally",
            href: "/wally",
            className: "",
        };

        return (
            <Box display="flex" column position="relative" className="transition-item-wrapper" fullHeight fullWidth>
                <Box display="flex" column className={classNames(transitionState)} fullHeight>
                    <PageTransition timeout={transitionState ? 600 : 0}>
                        <div className="transition-item full-height">
                            <Box
                                className={classNames("view-app", aditionalClassName, {
                                    "padding-for-access-bar": !noBar && isMobileNative,
                                })}
                                background="menu-background">
                                <ErrorBoundary>
                                    <Notification scopeToShow="menu" />
                                    <Head title={title} onBack={handleBack} />
                                    <Box className="py-5">
                                        <Stepper
                                            currentStep={step}
                                            completed={completed}
                                            stepsList={stepsList}
                                            showLabelMobile
                                        />
                                    </Box>
                                    <LoadingGlobalProvider>
                                        <MainContainer loadChildrenAtOnce>
                                            <ReceivedComponent {...matchProps} {...extras} />
                                        </MainContainer>
                                    </LoadingGlobalProvider>
                                </ErrorBoundary>
                            </Box>
                        </div>
                    </PageTransition>

                    {isMobileNative && !noBar && <AccessBar options={accessBarBtns} wally={wally} />}
                </Box>
            </Box>
        );
    };

    const getDesktopLayout = (matchProps) => {
        const extras = { isMobile, isDesktop, isTablet, isMobileNative, ...componentProps };
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";

        return (
            <ErrorBoundary>
                <ResizableComponent onResize={handlerResizeEventSidebar}>
                    <Box
                        className={classNames("app-default-container", {
                            "sidebar-collapsed": isSidebarCollapsed,
                        })}>
                        <Box component="header" className="app-default-header">
                            <NavigationBarDesktop />
                        </Box>
                        <Box
                            component="nav"
                            role="menu"
                            ariaLabel="Menu sidebar"
                            onClick={() => {
                                dispatch(desktopActions.changeStatusSidebarCollapsed(false));
                            }}
                            className="app-default-sidebar">
                            <Menu isMobile={isMobile} />
                            <p className="app-version">
                                {/* global process */}
                                <I18n id="global.version" component="small" /> {process.env.REACT_APP_VERSION}{" "}
                                {buildNumber}
                            </p>
                        </Box>
                        <Box
                            component="main"
                            background="menu-background"
                            className={classNames("app-default-main", {
                                "main-full-height": !scrollable,
                            })}
                            {...(scrollable && { scrollable: true })}>
                            <Box component="section" {...(!scrollable && { fullHeight: true })}>
                                <Head title={title} onBack={handleBack} />
                                <LoadingGlobalProvider>
                                    <MainContainer loadChildrenAtOnce>
                                        <Row>
                                            <Col md={3}>
                                                {step && (
                                                    <Box
                                                        component="section"
                                                        className="step-box py-8 pl-7 pr-3"
                                                        background="component-background"
                                                        borderRadius="default">
                                                        <Stepper
                                                            currentStep={step}
                                                            completed={completed}
                                                            stepsList={stepsList}
                                                        />
                                                    </Box>
                                                )}
                                            </Col>
                                            <Col md={9}>
                                                <ReceivedComponent {...matchProps} {...extras} />
                                            </Col>
                                        </Row>
                                    </MainContainer>
                                </LoadingGlobalProvider>
                            </Box>
                        </Box>
                    </Box>
                </ResizableComponent>
            </ErrorBoundary>
        );
    };

    return (
        <WithPermissionsRedirect somePermissions={somePermissions} permissions={permissions} comparator={comparator}>
                <UpdateUserDataProvider>
                    <Route
                        render={(matchProps) => (
                            <>{isMobile ? getMobileLayout(matchProps) : getDesktopLayout(matchProps)}</>
                        )}
                    />
                </UpdateUserDataProvider>
        </WithPermissionsRedirect>
    );
};

DefaultStepperLayout.propTypes = {
    aditionalClassName: string,
    dispatch: func.isRequired,
    isDesktop: bool,
    isMobile: bool,
    isTablet: bool,
    isMobileNative: bool,
    component: func.isRequired,
    componentProps: shape({}),
    transition: oneOf([
        "transition-drill-out",
        "transition-drill-in",
        "transition-flow-close",
        "transition-flow-open",
        "transition-change-feature",
        "",
    ]),
    location: shape({
        pathname: string.isRequired,
        state: shape({
            transition: oneOf([
                "noTransition",
                "transition-drill-out",
                "transition-drill-in",
                "transition-flow-close",
                "transition-flow-open",
                "transition-change-feature",
            ]),
        }),
    }).isRequired,
    withoutLayout: bool,
    somePermissions: bool,
    permissions: arrayOf(string),
    comparator: func,
    scrollable: bool,
    isSidebarCollapsed: bool.isRequired,
    noBar: bool,
    stepsList: shape({}).isRequired,
    step: number.isRequired,
    completed: number,
    title: string,
    customBackURL: string,
};

DefaultStepperLayout.defaultProps = {
    aditionalClassName: null,
    componentProps: null,
    isDesktop: false,
    scrollable: true,
    isMobile: false,
    isTablet: false,
    isMobileNative: false,
    transition: "",
    withoutLayout: false,
    somePermissions: false,
    permissions: null,
    comparator: null,
    noBar: false,
    completed: null,
    title: null,
    customBackURL: null,
};

const mapStateToProps = (state) => ({
    isSidebarCollapsed: desktopSelectors.isSidebarCollapsed(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(DefaultStepperLayout)));
